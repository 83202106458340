<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                                exact>
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
                        :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                        :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>

            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Mill Id </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="millId"
                                    :items="millIds" default="" item-value="mill_id" item-text="mill_name" label="Mill Id"
                                    @change="(event) => getMachTypeFilter(event)" clearable>
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>


                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Mach Type </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="machType"
                                    :items="machTypes" default="" item-value="mach_type" item-text="descr" label="Mach Type"
                                      clearable @change="(event) => getFilterJenis(event)">
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Jenis </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="jenis"
                                    :items="jenises" default="" label="Jenis" item-value="jenis" item-text="jenis"
                                      clearable @change="(event) => getBarangJadi(event)">
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Barang Jadi </h6>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="barangJadi"
                                    :items="barangJadies" default="" label="Barang Jadi" item-value="barangjadiid" item-text="namabarang"
                                      clearable>
                                      <!-- @change="(event) => getBrand(event)" -->
                                    <!-- :disabled="disabledRegion" -->
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Order Age(start) </h6>
                                <v-text-field solo v-model="orderAgeModel" label="Order Age(start)" dense></v-text-field>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Order Age(end) </h6>
                                <v-text-field solo v-model="orderAgeEndModel" label="Order Age(end)" dense></v-text-field>
                            </v-col>
                            
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">Start Period <strong style="color:red;">*</strong><span></span></h6>
                                <v-menu ref="modal" 
                                v-model="modal" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period" 
                                        label="Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period" 
                                        type="date" 
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" xs="12" sm="3" md="2">
                                <h6 class="blue-lcd mb-1 pa-1">To Period </h6>
                                <v-menu ref="modal_to_period" 
                                v-model="modal_to_period" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period_to" 
                                        label="To Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period_to" 
                                        type="date" 
                                        no-title @input="modal_to_period = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" xs="12" sm="2" md="1">
                                <v-btn class="mt-8 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="getPullData()">Search</v-btn>
                            </v-col>
                            
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-5">
            <v-col class="col-12">
                <v-card outlined class="pa-md-5">
                    <h6 class="cyan-text text-darken-3 m-0">Order Backlog Analysis</h6>
                    <div id="container1" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mb-5" v-if="tableVisible">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="formattedData"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    :items-per-page="10"
                                    fixed-header
                                    height="530"
                                    :divider="true"
                                    :light="true"
                                    :search="searchItem"
                                >        
                                    <template v-slot:top>
                                        <v-toolbar flat color="white">
                                            <div class="d-flex w-100">
                                                <h6 class="cyan-text text-darken-3 m-0">{{ tableTitle }}</h6>
                                                <v-spacer></v-spacer>
                                                <v-btn class="border-12 mr-4" style="padding: 19px;" small color="primary" elevation="2" >
                                                    <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :fields="headersColumn"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        Export
                                                    </download-excel> 
                                                </v-btn>
                                                <v-text-field solo style="max-width: 300px;" class="border-12" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.order_id2`]="{ item }">
                                            <v-menu
                                                bottom
                                                left
                                                v-if="item.order_id2 !== null"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        color="white"
                                                        class="text-center"
                                                    >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                            <v-list dense>
                                                <v-list-item @click="showFileDetail(item)">
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-eye</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Detail</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                            </v-menu>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
                v-model="dialogDetails"
                persistent
                max-width="1200px"
                hide-overlay
                transition="dialog-bottom-transition"
                >
                <v-card class="rounded-l">
                    <v-toolbar
                    color="#3b78d4"
                    dark
                    >
                    <v-card-title>Order Note
                    <v-spacer></v-spacer>
                        </v-card-title>
                        <v-toolbar-title></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-card-title class="text-center mb-0 pb-0">
                        <v-row>
                            <v-col cols="12">
                                <div class="d-flex">
                                    <v-btn
                                        class="mx-2"
                                        fab
                                        x-small
                                        color="#e83e8c;"
                                        @click="[dialogDetails = false]"
                                        >
                                        <v-icon>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                            <v-divider></v-divider>
                        </v-row>
                    </v-card-title>
                    </v-toolbar>
                    <v-card-text class="ma-0">
                        <v-data-table
                            dense
                            :headers="headersDetails"
                            :items="itemListsDetails"
                            class="elevation-1 mt-3"
                            :items-per-page="30"
                            :footer-props="{
                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                            }"
                            :loading="$store.state.overlay"
                            height="400"
                            fixed-header
                            :divider="true"
                            :light="true"
                            :search="searchItem" 
                            >    
                            <template v-slot:top>
                                <v-toolbar flat color="white" class="mt-0 mb-0">
                                    <div class="d-flex w-100">
                                        <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        <v-spacer></v-spacer>
                                    </div>
                                </v-toolbar>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-dialog>
    </v-container>
</template>

<script>
import {OrderBacklogAnalysis} from "../../../backend-api/gbrk/analysis/order_backlog_analysis"
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return{
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            breadcumbs: [
                {
                    text: 'GBRK',
                    disabled: false,
                    href: '/admin/gbrk',
                },
                {
                    text: 'GBRK Analysis',
                    disabled: true,
                    href: '/admin/gbrk',
                },
                {
                    text: 'Order Backlog Analysis',
                    disabled: true
                }
            ],
            // filter
            millId: '',
            millIds: [],
            machType: '',
            machTypes: [],
            jenis: '',
            jenises: [],
            barangJadi: '',
            barangJadies: [],
            modal: false,
            period: '',
            modal_to_period: false,
            period_to: '',
            // chart
            x: window.matchMedia("(max-width: 991px)"),
            // table detail
            tableVisible: false,
            tableTitle:'',
            header:[
                { text: '', value: 'order_id2', align: 'left', sortable: false },
                {
                    text: 'Mill',
                    align: 'start',
                    value: 'mill_id', 
                    align:'left'},
                { text: 'Order No', value: 'order_id' , align:'left'},
                { text: 'Order Type', value: 'ord_type' , align:'left'},
                { text: 'Item Num', value: 'item_num' , align:'left'},
                { text: 'Mach Type', value: 'mach_type' , align:'left'},
                { text: 'Jenis', value: 'material' , align:'left'},
                { text: 'Kode Barang Jadi', value: 'barangjadiid' , align:'left'},
                { text: 'Barang jadi', value: 'namabarang' , align:'left'},
                { text: 'Length', value: 'panjang' , align:'right'},
                { text: 'Qty Order', value: 'qty_order' , align:'right'},
                // { text: 'Qty Prod', value: 'qty_prod' , align:'right'},
                // { text: 'Qty Ship', value: 'qty_ship' , align:'right'},
                // { text: 'Qty Replace', value: 'qty_replace' , align:'right'},
                { text: 'Qty Outstanding', value: 'qty_outstanding' , align:'right'},
                { text: 'Outstanding (Kg)', value: 'kg_outstanding' , align:'right'},
                // { text: 'Actions',  value: 'actions', sortable: false },
            ],
            searchItem: '',
            dataTable:[],
            dialogDetails: false,
            reqBodyDetail:[],
            headersColumn:{
                'Trans Date': 'label', 
                'Mill': 'mill_id',
                'Order No' : 'order_id',
                'Order Type' : 'ord_type',
                'Item Num' : 'item_num',
                'Mach Type' : 'mach_type',
                'Jenis': 'material',
                'Kode Barang Jadi': 'barangjadiid',
                'Barang jadi': 'namabarang',
                'Length': 'panjang',
                'Qty Order': 'qty_order',
                // 'Qty Prod': 'qty_prod',
                // 'Qty Ship': 'qty_ship',
                // 'Qty Replace': 'qty_replace',
                'Qty Outstanding': 'qty_outstanding',
                'Outstanding (Kg)': 'kg_outstanding'
            },
            headersDetails:[
                { text: 'Order Id', value: 'order_id', align: 'left', sortable: false },
                {
                    text: 'TR Seq',
                    align: 'start',
                    value: 'tr_seq', 
                    align:'left'},
                { text: 'Cat ID', value: 'cat_id' , align:'left'},
                { text: 'Note 1', value: 'note1' , align:'left'},
        
            ],
            itemListsDetails:[],
            orderAgeModel: '',
            orderAgeEndModel: ''
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        this.tableVisible = false
        await this.getFilterMill()
        this.$store.dispatch('setOverlay', false)
    },
    computed:{
        formattedData() {
            return this.objectFormater(this.dataTable)
        },
    },
    methods:{
        tr_datatable(item) {
            // console.log(item);
            var rowClass = '';

            if (item.req_ship_date) {
                const currentDate = new Date();
                const reqShipDate = new Date(item.req_ship_date);
                const sevenDaysLater = new Date(currentDate);
                sevenDaysLater.setDate(currentDate.getDate());

                if (reqShipDate < sevenDaysLater) {
                    rowClass = 'merah'; 
                } else {
                    rowClass = 'tr_datatable'; 
                }
            } else {
                rowClass = 'tr_datatable';
            }

            return rowClass;
        },
        // tr_datatable(item) {
        //     console.log(item)
        //     var rowClass = '';
        //     if (item.req_ship_date) {
        //         rowClass = 'merah';
        //     }else{
        //         rowClass = 'tr_datatable';
        //     }
        //     return rowClass;
           
        // },
        objectFormater(_data){
            return _data.map(item => ({
                ...item,
                ord_type: this.formatOrderType(item.ord_type),
                panjang: this.formatNumber(item.panjang, 0, 2),
                qty_order: this.formatNumber(item.qty_order, 0, 0),
                // qty_prod: this.formatNumber(item.qty_prod, 0, 0),
                // qty_ship: this.formatNumber(item.qty_ship, 0, 0),
                // qty_replace: this.formatNumber(item.qty_replace, 0, 0),
                qty_outstanding: this.formatNumber(item.qty_outstanding, 0, 0),
                kg_outstanding: this.formatNumber(item.kg_outstanding, 0, 0),
            }));
        },
        formatNumber(number, minFrac, maxFrac) {
            var a = parseFloat(number)
            return a.toLocaleString(undefined, {
                minimumFractionDigits: minFrac,
                maximumFractionDigits: maxFrac
            });
        },
        formatOrderType(orderType){
            var a = ''
            switch (orderType) {
                case 'O':
                    a = "Customer Order"
                    break;
                case 'I':
                    a = "Internal Order"
                    break;
                case 'S':
                    a = "Stock Order"
                    break;
                case 'K':
                    a = "Kontrak Order"
                    break;
                case 'C':
                    a = "Coil Order"
                    break;
                case 'R':
                    a = "Return Order"
                    break;
                case 'Q':
                    a = "Quote Order"
                    break;
                case 'N':
                    a = "Inner Use"
                    break;
                case 'A':
                    a = "Sample Order"
                    break;
                case 'V':
                    a = "Avalan Order"
                    break;
                case 'D':
                    a = "Avalan Order"
                    break;
                case 'T':
                    a = "Pengambilan Kontrak"
                    break;
                case 'F':
                    a = "Order CSR"
                    break;
                case 'G':
                    a = "Order Express"
                    break;
                case 'B':
                    a = "Order Special"
                    break;
                default:
                    a = orderType
            }
            return a
        },
        async getFilterMill(){
            const _activeFlag = 'Y'
            const respData = await OrderBacklogAnalysis.fetchMillFilter(`?active_flag=${_activeFlag}`, null, false, false, false)
            if (respData.status === 200) {
                this.millIds = respData.data.data
                this.machTypes = []
                this.jenises = []
                this.barangJadies = []
                this.machType = ''
                this.jenis = ''
                this.barangJadi = ''
            }
        },
        async getMachTypeFilter(){
            const _activeFlag = 'Y'
            const respData = await OrderBacklogAnalysis.fetchMachTypeFilter(`?active_flag=${_activeFlag}&mill_id=${this.millId}`, null, false, false, false)
            if (respData.status === 200) {
                this.machTypes = respData.data.data
                this.jenises = []
                this.barangJadies = []
                this.jenis = ''
                this.barangJadi = ''
            }
        },
        async getFilterJenis(){
            const _activeFlag = 'Y'
            const respData = await OrderBacklogAnalysis.fetchJenisFilter(`?active_flag=${_activeFlag}&mach_type=${this.machType}`, null, false, false, false)
            if (respData.status === 200) {
                this.jenises = respData.data.data
                this.barangJadies = []
                this.barangJadi = ''
            }
        },
        async getBarangJadi(){
            const _activeFlag = 'Y'
            const respData = await OrderBacklogAnalysis.fetchBarangJadiFilter(`?active_flag=${_activeFlag}&jenis=${this.jenis}&mach_type=${this.machType}`, null, false, false, false)
            if (respData.status === 200) {
                this.barangJadies = respData.data.data
            }
        },
        async getPullData(event){
            if(this.period === ''){
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill start date",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true)
                var periodStart = '';
                var periodEnd = '';
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 10) : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)
                var reqBody = {
                    "mill_id": this.millId,
                    "mach_type": this.machType,
                    "jenis": this.jenis,
                    "barangjadiid": this.barangJadi,
                    "period_start": periodStart,
                    "period_end": periodEnd,
                    "orderAge": this.orderAgeModel,
                    "orderAgeEnd": this.orderAgeEndModel
                }
                var data = await OrderBacklogAnalysis.fetchIndex(``, reqBody, false, false, false)
                const rawMatData = data.data.data.lack_raw_mat
                const blmProdData = data.data.data.blm_prod
                const readyToShipData = data.data.data.ready_to_ship
                if (data.status != 200) {
                    this.$store.dispatch('setOverlay', false)
                    this.snackbar = {
                        color: "warning",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Warning",
                        text: "Please retry",
                        visible: true
                    };
                    return false
                }

                var dt1 = [];
                rawMatData.forEach(item => {
                    dt1.push({
                        "label": item.label,
                        "y": parseFloat(item.y)
                    });
                });
                var dt2 = [];
                blmProdData.forEach(item => {
                    dt2.push({
                        "label": item.label,
                        "y": parseFloat(item.y)
                    });
                });
                var dt3 = [];
                readyToShipData.forEach(item => {
                    dt3.push({
                        "label": item.label,
                        "y": parseFloat(item.y)
                    });
                });
                this.$store.dispatch('setOverlay', false)
                this.tableVisible = false
                this.dataTable = []
                this.getchart(dt1, dt2, dt3, "container1", 'column', "", "")
            }
        },
        // chart
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        getchart(dt1, dt2, dt3, container, type, title, subtitle){
            var chart = new CanvasJS.Chart(container, {            
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                title:{
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                subtitles:[
                    {
                        text: subtitle,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                toolTip:{
                    shared:true,
                    yValueFormatString: "#,###",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                axisX: {
                    labelFormatter: function (e) {
                        return (new Date(new Date(e.label) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
                    },
                    interval: 1,
                    intervalType: "day"
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Weight (Ton)",
                    labelFormatter: this.addSymbols,
                },

                data: [
                    { 
                        type: type,
                        name: "Lack Raw Mat",   
                        showInLegend: true,
                        color: "#01579B",
                        dataPoints: dt1,
                        yValueFormatString: "#,###.##",
                        click: this.onClickOne
                    },
                    {
                        type: type,
                        name: "Not Yet Produced",
                        color: "#FFC300",
                        showInLegend: true,
                        dataPoints: dt2,
                        yValueFormatString: "#,###.##",
                        click: this.onClickTwo
                    },
                    {
                        type: type,
                        name: "Ready To Ship",
                        showInLegend: true,
                        dataPoints: dt3,
                        yValueFormatString: "#,###.##",
                        click: this.onClickThree
                    }
                ]
            })
            chart.render();
        },

        async onClickOne(e){
            var date = (new Date(new Date(e.dataPoint.label) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.reqBodyDetail = {
                "mill_id": this.millId,
                "mach_type": this.machType,
                "jenis": this.jenis,
                "barangjadiid": this.barangJadi,
                "period":  date,
                "tr_type": 2,
                "orderAge": this.orderAgeModel,
                "orderAgeEnd": this.orderAgeEndModel
            }
            this.tableTitle = `List of Order Lack on Raw Material per Backlog Date ${date}`
            await this.getDetail(this.reqBodyDetail)

        },

        async onClickTwo(e){
            var date = (new Date(new Date(e.dataPoint.label) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.reqBodyDetail = {
                "mill_id": this.millId,
                "mach_type": this.machType,
                "jenis": this.jenis,
                "barangjadiid": this.barangJadi,
                "period": date,
                "tr_type": 3,
                "orderAge": this.orderAgeModel,
                "orderAgeEnd": this.orderAgeEndModel
            }
            this.tableTitle = `List of Order not yet Produced per Backlog Date ${date}`
            await this.getDetail(this.reqBodyDetail)
            // this.showFileDetail(item)
        },

        async onClickThree(e){
            var date = (new Date(new Date(e.dataPoint.label) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.reqBodyDetail = {
                "mill_id": this.millId,
                "mach_type": this.machType,
                "jenis": this.jenis,
                "barangjadiid": this.barangJadi,
                "period": date,
                "tr_type": 4,
                "orderAge": this.orderAgeModel,
                "orderAgeEnd": this.orderAgeEndModel
            }
            this.tableTitle = `List of Order Ready to Ship per Backlog Date ${date}`
            await this.getDetail(this.reqBodyDetail)
            // this.showFileDetail(item)
           
        },

        async getDetail(e){
            this.$store.dispatch('setOverlay', true)
            var data = await OrderBacklogAnalysis.fetchDetail(``, this.reqBodyDetail, false, false, false)
            this.$store.dispatch('setOverlay', false)
            if (data.status != 200) {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please retry",
                    visible: true
                };
                return false
            } else {
                this.tableVisible = true
                this.dataTable = []
                this.dataTable = data.data.data
            }
        },
        showFileDetail(item){
        console.log(item);
          this.order_id2 = item.order_id2
          this.dialogDetails = true
          this.getOrder()
          
        },
        async getOrder(item){
          await axios.get(`${process.env.VUE_APP_URL}/api/v3/gbrk/analysis/order-backlog-analysis/OrderNote?order_id=${this.order_id2 ? this.order_id2 : ''}`, { 
              headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
          })
          .then(res => {
            //console.log(res.data)
            this.itemListsDetails = res.data.datas
           
          });
        },    
        async exportExcel(){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
                const respData = await OrderBacklogAnalysis.fetchDetail(``, this.reqBodyDetail, false, false, false)
                return this.objectFormater(respData.data.data)
            }
        },

        startDownload(){
            if (this.period === '') {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Please fill period",
                    visible: true
                };
                return false;
            } else {
                this.$store.dispatch('setOverlay', true)
            }
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },
    watch:{},
}
</script>

<style>
    .tr_datatable{
        background-color: #ffffff !important;
    }
    .merah{
        background-color: #862727 !important;
        color: #ffffff;
    }
</style>