import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "@/components/Layouts/Default_Dashboard.vue";
import Children from "@/layouts/Children.vue";
import Master from "@/layouts/Master.vue";
import store from "@/store/index.js";
// import Home from "@/views/Home.vue";
// import Overview from "@/views/Overview.vue";
// import Profile from "@/views/Profile.vue";

//KKA
// import AnalyticsDashboard from "@/views/kka/AnalyticsDashboard.vue";
// import AverageWeightMeterKKA from "@/views/kka/AverageWeightMeter.vue";
// import CostRatioKKA from "@/views/kka/CostRatio.vue";
// import DeliveryAnalysisKKA from "@/views/kka/DeliveryAnalysis.vue";
// import DowntimeAnalysisKKA from "@/views/kka/DowntimeAnalysis.vue";
// import MaterialAvailabilityKKA from "@/views/kka/MaterialAvailability.vue";
// import MillStockKKA from "@/views/kka/MillStock.vue";
// import OrderAnalysisKKA from "@/views/kka/OrderAnalysis.vue";
// import OrderShortageKKA from "@/views/kka/OrderShortage.vue";
// import OrderStatusKKA from "@/views/kka/OrderStatus.vue";
// import OrderToShipPerformanceKKA from "@/views/kka/OrderToShipPerformance.vue";
// import OverviewKKA from "@/views/kka/Overview.vue";
// import PiutangReportKKA from "@/views/kka/PiutangReport.vue";
// import PurchaseAnalysisKKA from "@/views/kka/PurchaseAnalysis.vue";
// import QualityPerformanceKKA from "@/views/kka/QualityPerformance.vue";
// import ShippingAnalysisKKA from "@/views/kka/ShippingAnalysis.vue";
// import LoggerVsProdKKA from "@/views/kka/production/LoggerVsProd.vue";
// // import OrderCapacityKKA from "@/views/kka/OrderCapacity.vue";
// import OverallEquipmentEffectivenessKKA from "@/views/kka/OverallEquipmentEffectiveness.vue";
// import OrderCapacityKKA from "@/views/kka/production_perfomance/MachOrderCapacity.vue";
// // import CreateMPFKKA from "@/views/kka/CreateMPF.vue";
// import CustomerVisitKKA from "@/views/kka/CustomerVisit.vue";
// import MPFListKKA from "@/views/kka/MPFList.vue";
// import MPFSentKKA from "@/views/kka/MPFSent.vue";
// import TodayVisitKKA from "@/views/kka/TodayVisit.vue";
// import VisitReportKKA from "@/views/kka/VisitReport.vue";
// import MachineBacklog from "@/views/kka/production/MachineBacklog.vue";

//Production KKA
// import StockQuery from "@/views/kka/production/StockQuery.vue";
// import WarehouseTransfer from "@/views/kka/production/WarehouseTransfer.vue";

// //Maintenance KKA
// import OverviewKKAMaintenance from "@/views/kka/maintenance/Overview.vue";
// import UnscheduledMaintenanceQuery from "@/views/kka/maintenance/query/UnscheduledMaintenanceQuery.vue";
// import UnscheduledMaintenanceCreate from "@/views/kka/maintenance/unschedule/UnscheduledMaintenanceCreate.vue";

// //Maintenance Proyek
// import MaintenanceProyekHeader from "@/views/kka/maintenance/proyek/MaintenanceProyekHeader.vue";
// import ProyekMaintenance from "@/views/kka/maintenance/proyek/ProyekMaintenance.vue";

// //Master Maintenance KKA
// import MasterMachineCategory from "@/views/kka/maintenance/master/MachineCategory.vue";
// import MasterMachineStandart from "@/views/kka/maintenance/master/MachineCategoryStandart.vue";
// import MasterMaintenanceCategory from "@/views/kka/maintenance/master/MaintenanceCategoryList.vue";
// import MasterMaintenanceEmployee from "@/views/kka/maintenance/master/MaintenanceEmployeeList.vue";

//SR MALL
// import OverviewSRMALL from "@/views/srmall/Overview.vue";
// //Approval SR MALL
// import Invoice from "@/views/srmall/approval/Invoice.vue";
// import OverviewSRMALLApproval from "@/views/srmall/approval/Overview.vue";
// import PurchaseOrder from "@/views/srmall/approval/PurchaseOrder.vue";
// import PurchaseRequest from "@/views/srmall/approval/PurchaseRequest.vue";
// //Information SR MALL
// import InvMasterList from "@/views/srmall/information/InvMasterList.vue";
// import JournalYear from "@/views/srmall/information/JournalYear.vue";
// import OverviewSRMALLInformation from "@/views/srmall/information/Overview.vue";
// import PurchasingHutang from "@/views/srmall/information/PurchasingHutang.vue";
// import WarehouseStock from "@/views/srmall/information/WarehouseStock.vue";
// import WorkOrder from "@/views/srmall/information/WorkOrder.vue";

//MKI
// import OverviewMki from "@/views/mki/Overview.vue";
// import PurchaseOrderMki from "@/views/mki/PurchaseOrderMki.vue";
// import PurchaseRequestMki from "@/views/mki/PurchaseRequestMKI.vue";

// //MKM
// import OverviewMKm from "@/views/mkm/Overview.vue";
// import PurchaseOrderMkm from "@/views/mkm/PurchaseOrderMkm.vue";
// import PurchaseRequestMkm from "@/views/mkm/PurchaseRequestMkm.vue";

// //MGS
// import OverviewMgs from "@/views/mgs/Overview.vue";
// import PurchaseRequestMgs from "@/views/mgs/PurchaseRequestMgs.vue";

// //Starcon
// import OverviewStarcon from "@/views/starcon/Overview.vue";
// // Approval Starcon
// import PurchaseOrderStarcon from "@/views/starcon/approval/PurchaseOrder.vue";
// import PurchaseRequestStarcon from "@/views/starcon/approval/PurchaseRequest.vue";
// import QuotationStarcon from "@/views/starcon/approval/Quotation.vue";
// import SalesContractStarcon from "@/views/starcon/approval/SalesContract.vue";
//Sales Activity
// import CustomerVisitStarcon from "@/views/starcon/sales_activity/CustomerVisit.vue";
// import TodayVisitStarcon from "@/views/starcon/sales_activity/TodayVisit.vue";
// import ActivityReportStarcon from "@/views/starcon/sales_activity/ActivityReport.vue";
// //HRIS Starcon
// import AnnualLeaveStarcon from "@/views/starcon/hris/AnnualLeave.vue";
// import AttendanceQueryStarcon from "@/views/starcon/hris/AttendanceQuery.vue";
// import OverviewHRISStarcon from "@/views/starcon/hris/Overview.vue";
// //KMB
// import AnalyticDashboard from "@/views/kmb/AnalyticDashboard.vue";
// import AttendanceReportKMB from "@/views/kmb/AttendanceReportKMB.vue";
// import CreateOrder from "@/views/kmb/CreateOrder.vue";
// import CustomerVisit from "@/views/kmb/CustomerVisit.vue";
// import DebtReport from "@/views/kmb/DebtReport.vue";
// import DepoStockReport from "@/views/kmb/DepoStockReport.vue";
// import DepoStockValueReport from "@/views/kmb/DepoStockValueReport.vue";
// import InvoiceAnalysis from "@/views/kmb/InvoiceAnalysis.vue";
// import KPIAnalysis from "@/views/kmb/KPIAnalysis.vue";
// import MPFListKMB from "@/views/kmb/MPFList.vue";
// import MPFSentKMB from "@/views/kmb/MPFSent.vue";
// import MachineBackLog from "@/views/kmb/MachineBackLog.vue";
// import MaterialAvailReport from "@/views/kmb/MaterialAvailReport.vue";
// import OrderAnalysis from "@/views/kmb/OrderAnalysis.vue";
// import OrderList from "@/views/kmb/OrderList.vue";
// import OrderReadyToShipReport from "@/views/kmb/OrderReadyToShipReport.vue";
// import OrderReport from "@/views/kmb/OrderReport.vue";
// import OrderShipPerformance from "@/views/kmb/OrderShipPerformance.vue";
// import OrderShortageReport from "@/views/kmb/OrderShortageReport.vue";
// import OverviewKMB from "@/views/kmb/Overview.vue";
// import OverviewHrisKMB from "@/views/kmb/OverviewHRIS.vue";
// import PiutangReport from "@/views/kmb/PiutangReport.vue";
// import ProjectDashboard from "@/views/kmb/ProjectDashboard.vue";
// import ProjectTracking from "@/views/kmb/ProjectTracking.vue";
// import PurchaseAnalysis from "@/views/kmb/PurchaseAnalysis.vue";
// import ShippingAnalysis from "@/views/kmb/ShippingAnalysis.vue";
// import ShippingReport from "@/views/kmb/ShippingReport.vue";
// import Telemarketing from "@/views/kmb/Telemarketing.vue";
// import TodayVisit from "@/views/kmb/TodayVisit.vue";
// import VisitReport from "@/views/kmb/VisitReport.vue";
// import App1 from "@/views/kmb/blank2.vue";
// import BadCustomer from "@/views/kmb/branch_perfomance/BadCustomer.vue";
// import SalesProcurement from "@/views/kmb/branch_perfomance/SalesProcurement.vue";
// import OrderDashboard from "@/views/kmb/order/OrderDashboard.vue";
// import OrderDashboardByDepo from "@/views/kmb/order/OrderDashboardByDepo.vue";

//WBJ
// import OverviewWbj from "@/views/wbj/Overview.vue";
//Approval WBJ
// import PurchaseOrderWbj from "@/views/wbj/approval/PurchaseOrder.vue";
// import PurchaseRequestWbj from "@/views/wbj/approval/PurchaseRequest.vue";

//Sunrise Mill
// import OverviewSm from "@/views/sm/Overview.vue";
//Approval Sunrise Mill
// import DoApprovalSm from "@/views/sm/approval/DoApprovalSm.vue";
// import PruApprovalSm from "@/views/sm/approval/PruApprovalSm.vue";

//KBT
// import OverviewKbt from "@/views/kbt/Overview.vue";

//Tobang
// import OverviewTobang from "@/views/tobang/Overview.vue";

//Sunrise Steel
// import CashFlow from "@/views/sr/finance/CashFlow.vue";
// import Hutang from "@/views/sr/finance/Hutang.vue";
// import Piutangainamo from "@/views/sr/finance/Piutangainamo.vue";
// import PurchaseorderSR from "@/views/sr/finance/PurchaseorderSR.vue";
// import PrApproval from "@/views/sr/financeApproval/PrApproval.vue";
// import POReturApproval from "@/views/sr/financeApproval/POReturApproval.vue";
// import PageNotFound from "@/views/PageNotFound.vue";

//Aruna Harja Kajayan
// import OverviewAruna from "@/views/aruna/Overview.vue";
//Approval Aruna
// import DoApprovalAruna from "@/views/aruna/approval/DoApprovalAruna.vue";
// import PruApprovalAruna from "@/views/aruna/approval/PruApprovalAruna.vue";

//Amerta
// import OverviewAmerta from "@/views/amerta/Overview.vue";
// import ContractApprovalAmerta from "@/views/amerta/order/ContractApproval.vue";
// import PaymentApprovalAmerta from "@/views/amerta/finance/PaymentApproval.vue";

//KRI
// import OverviewKRI from "@/views/kri/Overview.vue";
// import SalesAnalysisKRI from "@/views/kri/sales/SalesAnalysis.vue";

Vue.use(VueRouter);

function checkAuth(to, from, resolve, reject) {
  store.dispatch('setOverlay', true)  
  axios.get(`${process.env.VUE_APP_URL}/api/cek_token`, { 
      headers: { Authorization: `Bearer ${store.getters.isTokenUser}` } 
  }).then(response => {
      // Token is valid, so continue
      store.dispatch('setOverlay', false)  
      resolve();
  }).catch(error => {
      // There was an error so redirect
      router.push({ path: "/" });
  })
}

function checkAuthAccessMenuUser(to, from, resolve, reject) {
  store.dispatch('setOverlay', true)  

  axios.get(`${process.env.VUE_APP_URL}/api/cek_token_menu?route=${to.fullPath}`, { 
      headers: { Authorization: `Bearer ${store.getters.isTokenUser}` } 
  }).then(response => {

      if (response.data === true) {
        store.dispatch('setOverlay', false)  
        resolve();
      } else {
        store.dispatch('setOverlay', false)  
        router.push({ path: "/PageNotFound" });
      }
      // Token is valid, so continue
  }).catch(error => {
      // There was an error so redirect
      store.dispatch('setOverlay', false)  
      router.push({ path: "/PageNotFound" });
  })
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  {
    path: "/password/reset",
    name: "ForgetPassword",
    component: () =>
      import("@/views/ForgetPassword.vue"),
  },
  {
    path: "/reset/password/:link",
    name: "ResetPassword",
    component: () =>
      import("@/views/ResetPassword.vue"),
  },
  {
    path: "/absencePermit",
    name: "absencePermit",
    component: () =>
      import("@/views/absencePermit.vue"),
  },
  // {
  //   path: "/admin/undermaintenance",
  //   name: "UnderMaintenance",
  //   component: () =>
  //     import("@/views/UnderMaintenance.vue"),
  // },
  {
    path: "/admin",
    // component: DashboardLayout,
    component: () => import("@/components/Layouts/Default_Dashboard.vue"),
    redirect: "/admin/dashboard",
    beforeEnter: checkAuth,
    children: [
      {
        path: 'dashboard',
        name: 'Overview',
        component: () => import("@/views/Overview.vue")
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import("@/views/Profile.vue")
      },
      {
        path: 'pr',
        name: 'Profile',
        component: () => import("@/views/pr.vue"),
      },
      {
        path: 'po',
        name: 'Profile',
        component: () => import("@/views/po.vue"),
      },
    ]
  },
  //KKA
  {
    path: "/admin/kka",
    component: Master,
    redirect: "/admin/kka/dashboard",
    beforeEnter: checkAuth,
    name: 'KKA',
    children: [
      {
        path: 'dashboard',
        name: 'OverviewKKA',
        component: () => import("@/views/kka/Overview.vue"),
      },
      {
        path: 'AnalyticsDashboard',
        name: 'AnalyticsDashboard',
        component: () => import("@/views/kka/AnalyticsDashboard.vue"),
      },
      {
        path: "hris",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'attendance',
            name: 'attendanceKka',
            component: () => import("@/views/kka/hris/Attendance.vue"),
          },
          {
            path: 'annual',
            name: 'AnnualLeaveKKA',
            component: () => import("@/views/kka/hris/AnnualLeave.vue"),
          },
          {
            path: 'overview',
            name: 'overviewHrisKka',
            component: () => import("@/views/kka/hris/Overview.vue"),
          }
        ]
      },
      {
        path: "e-reports",
        component: Children,
        redirect: "/admin/kka/e-reports",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'CostWeightRatioKKA',
            name: 'CostWeightRatioKKA',
            component: () => import("@/views/kka/CostWeightRatio.vue"),
          },
        ]
      },
      {
        path: "reports",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'MaterialAvailability',
            name: 'MaterialAvailabilityKKA',
            component: () => import("@/views/kka/MaterialAvailability.vue"),
          },
          {
            path: 'OrderShortage',
            name: 'OrderShortageKKA',
            component: () => import("@/views/kka/OrderShortage.vue"),
          },
          {
            path: 'PiutangReport',
            name: 'PiutangReporttKKA',
            component: () => import("@/views/kka/PiutangReport.vue"),
          },
          {
            path: 'OrderStatus',
            name: 'OrderStatusKKA',
            component: () => import("@/views/kka/OrderStatus.vue"),
          },
          {
            path: 'MillStock',
            name: 'MillStockKKA',
            component: () => import("@/views/kka/MillStock.vue")
          },
          {
            path: 'CostRatio',
            name: 'CostRatioKKA',
            component: () => import("@/views/kka/CostRatio.vue")
          },
          {
            path: 'deliv',
            name: 'Deliv Report',
            component: () => import("@/views/kka/Delive.vue")
          },
          {
            path: 'customer-history',
            name: 'CustomerHistoryKKA',
            component: () => import("@/views/kka/report/CustomerHistory.vue")
          },
          {
            path: 'hutang',
            name: 'HutangKKA',
            component: () => import("@/views/kka/finance/Hutang.vue")
          },
        ]
      },
      {
        path: "KKA-Analysis",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OrderAnalysis',
            name: 'OrderAnalysisKKA',
            component: () => import("@/views/kka/OrderAnalysis.vue")
          },
          {
            path: 'QualityPerformance',
            name: 'QualityPerformanceKKA',
            component: () => import("@/views/kka/QualityPerformance.vue")
          },
          {
            path: 'ShippingAnalysis',
            name: 'ShippingAnalysisKKA',
            component: () => import("@/views/kka/ShippingAnalysis.vue")
          },
          {
            path: 'DeliveryAnalysis',
            name: 'DeliveryAnalysisKKA',
            component: () => import("@/views/kka/DeliveryAnalysis.vue")
          },
          {
            path: 'OrderToShipPerformance',
            name: 'OrderToShipPerformanceKKA',
            component: () => import("@/views/kka/OrderToShipPerformance.vue")
          },
          {
            path: 'PurchaseAnalysis',
            name: 'PurchaseAnalysisKKA',
            component: () => import("@/views/kka/PurchaseAnalysis.vue")
          },
          {
            path: 'AverageWeightMeter',
            name: 'AverageWeightMeterKKA',
            component: () => import("@/views/kka/AverageWeightMeter.vue")
          },
          {
            path: 'RawMatBalance',
            name: 'RawMatBalanceKKA',
            component: () => import("@/views/kka/RawMatBalance.vue")
          },
          {
            path: 'order-backlog-analysis',
            name: 'order-backlog-analysis',
            component: () => import("@/views/kka/analysis/OrderBacklogAnalysis.vue")
          },
          {
            path: 'OrderVsCoilReceiving',
            name: 'OrderVsCoilReceivingKKA',
            component: () => import("@/views/kka/analysis/OrdervsCoilReceive.vue")
          },
          {
            path: 'OrderToShipPerformanceTwo',
            name: 'OrderToShipPerformanceTwoKKA',
            component: () => import("@/views/kka/analysis/OrderToShipPerformanceTwo.vue")
          },
        ]
      },
      {
        path: "ProductionPerformance",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'LoggerVsProd',
            name: 'LoggerVsProdKKA',
            component: () => import("@/views/kka/production/LoggerVsProd.vue")
          },
          {
            path: 'DowntimeAnalysis',
            name: 'DowntimeAnalysisKKA',
            component: () => import("@/views/kka/DowntimeAnalysis.vue")
          },
          {
            path: 'OrderCapacity',
            name: 'OrderCapacityKKA',
            component: () => import("@/views/kka/production_perfomance/MachOrderCapacity.vue")
          },
          {
            path: 'OverallEquipmentEffectiveness',
            name: 'OverallEquipmentEffectivenessKKA',
            component: () => import("@/views/kka/OverallEquipmentEffectiveness.vue")
          },
          {
            path: 'MachineBacklog',
            name: 'MachineBacklogKKA',
            component: () => import("@/views/kka/production/MachineBacklog.vue"),
          },
          {
            path: 'CoilRatio',
            name: 'CoilRatioKKA',
            component: () => import("@/views/kka/CoilRatio.vue")
          },
          {
            path: 'Slitting',
            name: 'SumarrySlitting',
            component: () => import("@/views/kka/SlitingSummarry.vue")
          },
         
        ]
      },
      {
        path: "mpf",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'MPFList',
            name: 'MPFListKKA',
            component: () => import("@/views/kka/MPFList.vue")
          },
          {
            path: 'MPFSent',
            name: 'MPFSentKKA',
            component: () => import("@/views/kka/MPFSent.vue")
          },
        ]
      },
      {
        path: "production",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'warehouse-tf',
            name: 'WarehouseTransfer',
            component: () => import("@/views/kka/production/WarehouseTransfer.vue")
          },
          {
            path: 'stock-query',
            name: 'StockQuery',
            component: () => import("@/views/kka/production/StockQuery.vue")
          },
          {
            path: 'hold-stock',
            name: 'HoldStock',
            component: () => import("@/views/kka/production/HoldStock.vue")
          },
          {
            path: 'coil-genealogy',
            name: 'CoilGenealogyKKA',
            component: () => import("@/views/kka/production/CoilGenealogy.vue")
          },
        ]
      },
      {
        path: "maintenance",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'dashboard',
            name: 'OverviewKKAMaintenance',
            component: () => import("@/views/kka/maintenance/Overview.vue")
          },
          //Master
          {
            path: 'master/machine_category_list',
            name: 'Machine Category',
            component: () => import("@/views/kka/maintenance/master/MachineCategory.vue"),
          },
          {
            path: 'master/maintenance_standart',
            name: 'Machine Standart',
            component: () => import("@/views/kka/maintenance/master/MachineCategoryStandart.vue"),
          },
          {
            path: 'master/master_category_maintenance',
            name: 'Maintenance Category',
            component: () => import("@/views/kka/maintenance/master/MaintenanceCategoryList.vue"),
          },
          {
            path: 'master/master_maintenance_empoyee',
            name: 'Maintenance Employee',
            component: () => import("@/views/kka/maintenance/master/MaintenanceEmployeeList.vue"),
          },
          //Maintenance
          {
            path: 'proyek',
            name: 'Proyek Maintenance',
            component: () => import("@/views/kka/maintenance/proyek/ProyekMaintenance.vue"),
          },
          {
            path: 'unscheduled-maintenance',
            name: 'Unschedule Maintenance',
            component: () => import("@/views/kka/maintenance/unschedule/UnscheduledMaintenanceCreate.vue"),
          },
          {
            path: 'query_maintenance',
            name: 'Query Maintenance',
            component: () => import("@/views/kka/maintenance/query/UnscheduledMaintenanceQuery.vue"),
          },
          //Proyek
          {
            path: 'maintenance-proyek',
            name: 'Proyek',
            component: () => import("@/views/kka/maintenance/proyek/MaintenanceProyekHeader.vue"),
          },
          // {
          //   path: 'stock-schedule-maintenance',
          //   name: 'Schedule Maintenance',
          //   component: ScheduleMaintenance
          // }
          {
            path: 'create',
            name: 'createMaintenanceKka',
            component: () => import("@/views/kka/maintenance/Create.vue"),
          },
          {
            path: 'index',
            name: 'indexMaintenanceKka',
            component: () => import("@/views/kka/maintenance/Index.vue"),
          },
          {
            path: 'generate',
            name: 'GenerateQrCodeMaintenanceKka',
            component: () => import("@/views/kka/maintenance/GenerateQrCode.vue"),
          },
          {
            path: 'Trend',
            name: 'MaintenanceTrend',
            component: () => import("@/views/kka/maintenance/Trend.vue"),
          },
        ]
      },
      {
        path: "SalesActivity",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'CustomerVisit',
            name: 'CustomerVisitKKA',
            component: () => import("@/views/kka/CustomerVisit.vue"),
          },
          {
            path: 'TodayVisit',
            name: 'TodayVisitKKA',
            component: () => import("@/views/kka/TodayVisit.vue"),
          },
          {
            path: 'VisitReport',
            name: 'VisitReportKKA',
            component: () => import("@/views/kka/VisitReport.vue"),
          },
        ]
      },
      {
        path: "Approval",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'Purchaseorder',
            name: 'purchaseorder',
            component: () => import("@/views/kka/DataApproval/po.vue")
          },
          {
            path: 'PurchaseRequest',
            name: 'PurchaseRequest',
            component: () => import("@/views/kka/DataApproval/pr.vue")
          },
        ]
      }
    ]
  },

  //SR MALL
  {
    path: "/admin/srmall",
    component: Master,
    redirect: "/admin/srmall/dashboard",
    beforeEnter: checkAuth,
    name: 'SRMALL',
    children: [
      {
        path: 'dashboard',
        name: 'OverviewSRMALL',
        // component: () => import("@/views/srmall/dashboard/OverviewSrmall"),
        component: () => import("@/views/srmall/Overview"),
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OverviewSrmall',
            name: 'OverviewSrmall',
            component: () => import("@/views/srmall/dashboard/OverviewSrmall"),
          },
        ]
      },
      {
        path: "approval",
        component: Children,
        redirect: "/admin/srmall/approval/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'dashboard',
            name: 'OverviewSRMALLApproval',
            component: () => import("@/views/srmall/approval/Overview.vue"),
          },
          {
            path: 'purchase-order',
            name: 'PurchaseOrder',
            component: () => import("@/views/srmall/approval/PurchaseOrder.vue"),
          },
          {
            path: '/admin/srmall/approval/invoice',
            name: 'InvoiceOrder',
            component: () => import("@/views/srmall/approval/Invoice.vue"),
          },
          {
            path: 'purchase-request',
            name: 'PurchaseRequest',
            component: () => import("@/views/srmall/approval/PurchaseRequest.vue"),
          },
        ]
      },
      {
        path: "information",
        component: Children,
        redirect: "/admin/srmall/information/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'dashboard',
            name: 'OverviewSRMALLInformation',
            component: () => import("@/views/srmall/information/Overview.vue"),
          },
          {
            path: 'work-order',
            name: 'WorkOrder',
            component: () => import("@/views/srmall/information/WorkOrder.vue"),
          },
          {
            path: 'tenant-invoice',
            name: 'InvMasterList',
            component: () => import("@/views/srmall/information/InvMasterList.vue"),
          },
          {
            path: 'warehouse',
            name: 'WarehouseStock',
            component: () => import("@/views/srmall/information/WarehouseStock.vue"),
          },
          {
            path: 'purchasing',
            name: 'PurchasingHutang',
            component: () => import("@/views/srmall/information/PurchasingHutang.vue"),
          },
          {
            path: 'journal',
            name: 'JournalYear',
            component: () => import("@/views/srmall/information/JournalYear.vue"),
          }
        ]
      },
    ]
  },
  //MKI
  {
    path: "/admin/mki",
    component: Master,
    redirect: "/admin/mki/dashboard",
    beforeEnter: checkAuth,
    name: "MKI",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewMki',
        component: () => import("@/views/mki/Overview.vue"),
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OverviewMki',
            name: 'OverviewMkii',
            component: () => import("@/views/mki/dashboard/OverviewMki"),
          },
        ]
      },
      {
        path: "approval",
        component: Children,
        redirect: "/admin/mki/approval/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'purchase-order',
            name: 'PurchaseOrderMki',
            component: () => import("@/views/mki/PurchaseOrderMki.vue"),
          },
          {
            path: 'purchase-request',
            name: 'PurchaseRequestMki',
            component: () => import("@/views/mki/PurchaseRequestMKI.vue"),
          },
        ]
      },
      {
        path: "hris",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'attendance',
            name: 'attendanceMki',
            component: () => import("@/views/mki/hris/Attendance.vue"),
          },
          {
            path: 'overview',
            name: 'overviewHrisMki',
            component: () => import("@/views/mki/hris/Overview.vue"),
          },
          {
            path: 'annual',
            name: 'AnnualLeaveMKI',
            component: () => import("@/views/mki/hris/AnnualLeave.vue"),
          },
        ]
      },
    ]
  },
//Mkm
  {
    path: "/admin/mkm",
    component: Master,
    redirect: "/admin/mkm/dashboard",
    name: "MKM",
    beforeEnter: checkAuth,
    children: [
      {
        path: 'dashboard',
        name: 'OverviewMkm',
        component: () => import("@/views/mkm/Overview.vue"),
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OverviewMkm',
            name: 'Dashboard',
            component: () => import("@/views/mkm/dashboard/OverviewMkm"),
          },
        ]
      },
      {
        path: "approval",
        component: Children,
        redirect: "/admin/mkm/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'purchase-order',
            name: 'PurchaseOrderMkm',
            component: () => import("@/views/mkm/PurchaseOrderMkm.vue"),
          },
          {
            path: 'purchase-request',
            name: 'PurchaseRequestMkm',
            component: () => import("@/views/mkm/PurchaseRequestMkm.vue"),
          },
        ]
      },
    ]
  },
//MGS
  {
    path: "/admin/mgs",
    component: Master,
    redirect: "/admin/mgs/dashboard",
    beforeEnter: checkAuth,
    name:"MGS",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewMgs',
        component: () => import("@/views/mgs/Overview.vue"),
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OverviewMgs',
            name: 'Dashboardd',
            component: () => import("@/views/mgs/dashboard/OverviewMgs"),
          },
        ]
      },
      {
        path: "approval",
        component: Children,
        redirect: "/admin/mgs/approval/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'purchase-request',
            name: 'PurchaseRequestMgs',
            component: () => import("@/views/mgs/PurchaseRequestMgs.vue"),
          },
          {
            path: 'maintenance',
            name: 'maintenanceMgs',
            component: () => import("@/views/mgs/UnderMaintenanceMgs"),
          },
        ]
      },
    ]
  },

  //Aruna Harja Kabayan
  {
    path: "/admin/aruna",
    component: Master,
    redirect: "/admin/aruna/dashboard",
    beforeEnter: checkAuth,
    name: "ARUNA",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewArunas',
        component: () => import("@/views/aruna/Overview.vue")
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OverviewAruna',
            name: 'Dashboards',
            component: () => import("@/views/aruna/dashboard/OverviewAruna"),
          },
        ]
      },
      {
        path: "approval",
        component: Children,
        redirect: "/admin/aruna/approval/dashboard",
        beforeEnter: checkAuth,
        children: [
          {
            path: 'do',
            name: 'DoApprovalAruna',
            component: () => import("@/views/aruna/approval/DoApprovalAruna.vue")
          },
          {
            path: 'PruApproval',
            name: 'PruApprovall',
            component: () => import("@/views/aruna/approval/PruApprovalAruna.vue")
          }
        ]
      }
    ]
  },

  // Amerta
  {
    path: "/admin/amerta",
    component: Master,
    redirect: "/admin/amerta/dashboard",
    beforeEnter: checkAuth,
    name: "AMERTA",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewAmertas',
        component: () => import("@/views/amerta/Overview.vue")
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OverviewAmerta',
            name: 'Dashboards',
            component: () => import("@/views/amerta/dashboard/OverviewAmerta"),
          },
        ]
      },
      {
        path: "order",
        component: Children,
        redirect: "/order/dashboard",
        beforeEnter: checkAuth,
        children: [
          {
            path: 'ContractApproval',
            name: 'ContractApprovalAmerta',
            component: () => import("@/views/amerta/order/ContractApproval.vue")
          },
        ]
      },
      {
        path: "finance",
        component: Children,
        redirect: "/finance/dashboard",
        beforeEnter: checkAuth,
        children: [
          {
            path: 'PaymentApproval',
            name: 'PaymentApprovalAmerta',
            component: () => import("@/views/amerta/finance/PaymentApproval.vue")
          },
        ]
      }
    ]
  },
  
  //viva
  {
    path: "/admin/viva",
    component: Master,
    redirect: "/admin/viva/dashboard",
    beforeEnter: checkAuth,
    name: "VIVA",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewViva',
        component: () => import("@/views/viva/OverviewViva"),
      },
      {
        path: "forecast",
        component: Children,
        redirect: "/admin/viva/forecast",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'forecast-vs-realization',
            name: 'Forecast Vs Realisasi',
            component: () => import("@/views/viva/forecast/forecast-realization"),
          },
          {
            path: 'analysis',
            name: 'Chart analysis',
            component: () => import("@/views/viva/forecast/analysis"),
          },
          {
            path: 'forecast-vs-realization-barang-jadi',
            name: 'ForecastVsRealisasiBarangJadi',
            component: () => import("@/views/viva/forecast/forecast-realization-barang-jadi"),
          },
        ]
      },
      {
        path: "MPF",
        component: Children,
        redirect: "/admin/viva/MPF",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'MPFUser',
            name: 'MPF User',
            component: () => import("@/views/viva/MPF/MPFUser"),
          },
          {
            path: 'MPFInbox',
            name: 'MPF Inbox',
            component: () => import("@/views/viva/MPF/MPFInbox"),
          },
          {
            path: 'MPFOutbox',
            name: 'MPF Outbox',
            component: () => import("@/views/viva/MPF/MPFOutbox"),
          },
        ]
      },
      {
        path: "HRD",
        component: Children,
        redirect: "/admin/viva/HRD",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'overview',
            name: 'overviewViva',
            component: () => import("@/views/viva/HRD/Overview"),
          },
          {
            path: 'EmployeeRequest',
            name: 'Employee Request',
            component: () => import("@/views/viva/HRD/EmployeeRequest"),
          },
          // {
          //   path: 'AbsencePermit',
          //   name: 'Absence Permit',
          //   component: () => import("@/views/viva/HRD/AbsencePermit"),
          // },
          {
            path: 'Permission',
            name: 'Permission',
            component: () => import("@/views/viva/HRD/Permission"),
          },
          {
            path: 'Employee',
            name: 'Employee',
            component: () => import("@/views/viva/HRD/Employee"),
          },
          {
            path: 'Attendance',
            name: 'Attendance',
            component: () => import("@/views/viva/HRD/Attendance"),
          },
        ]
      }
    ]
  },

  //Starcon
  {
    path: "/admin/sms",
    component: Master,
    redirect: "/admin/sms/dashboard",
    beforeEnter: checkAuth,
    name: "SMS",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewStarcon',
        component: () => import("@/views/starcon/Overview.vue"),
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OverviewSms',
            name: 'OverviewSms',
            component: () => import("@/views/starcon/dashboard/OverviewSms"),
          },
        ]
      },
      {
        path: "approval",
        component: Children,
        redirect: "/admin/sms/approval/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'purchase-request',
            name: 'PurchaseRequestStarcon',
            component: () => import("@/views/starcon/approval/PurchaseRequest.vue"),
          },
          {
            path: 'purchase-order',
            name: 'PurchaseOrderStarcon',
            component: () => import("@/views/starcon/approval/PurchaseOrder.vue"),
          },
          {
            path: 'quotation',
            name: 'QuotationStarcon',
            component: () => import("@/views/starcon/approval/Quotation.vue"),
          },
          {
            path: 'sales-contract',
            name: 'SalesContractStarcon',
            component: () => import("@/views/starcon/approval/SalesContract.vue"),
          },
          {
            path: 'comparison_price',
            name: 'mnHPHSms',
            component:() => import("@/views/starcon/approval/HPHApproval.vue"),
          },
          {
            path: 'purchase-deposit-request',
            name: 'PurchaseDepositRequestSms',
            component:() => import("@/views/starcon/approval/PurchaseDepositRequest.vue"),
          },
        ]
      },
      {
        path: "sales-activity",
        component: Children,
        redirect: "/admin/sms/sales-activity/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'customer-visit',
            name: 'CustomerVisitStarcon',
            component: () => import("@/views/starcon/sales_activity/CustomerVisit.vue"),
          },
          {
            path: 'visit-report',
            name: 'VisitReportStarcon',
            component: () => import("@/views/starcon/sales_activity/ActivityReport.vue"),
          },
          {
            path: 'today-visit',
            name: 'TodayVisitStarcon',
            component: () => import("@/views/starcon/sales_activity/TodayVisit.vue"),
          }
        ]
      },
      {
        path: "hris",
        component: Children,
        redirect: "/admin/sms/hris/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'overview',
            name: 'OverviewHRISStarcon',
            component: () => import("@/views/starcon/hris/Overview.vue"),
          },
          {
            path: 'attendance-query',
            name: 'AttendanceQueryStarcon',
            component: () => import("@/views/starcon/hris/AttendanceQuery.vue"),
          },
          {
            path: 'annual-leave',
            name: 'AnnualLeaveStarcon',
            component: () => import("@/views/starcon/hris/AnnualLeave.vue"),
          }
        ]
      },
      {
        path: "purchasing",
        component: Children,
        redirect: "/admin/sms/purchasing/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'mnuReportPRtoLPB',
            name: 'mnuReportPRtoLPB',
            component: () => import("@/views/starcon/purchasing/ReportPRtoLPB.vue"),
          },
        ]
      },
      {
        path: "finance",
        component: Children,
        redirect: "/admin/sms/finance/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'mnuReportPOtoPDP',
            name: 'mnuReportPOtoPDP',
            component: () => import("@/views/starcon/finance/ReportPOtoPDP.vue"),
          },
          {
            path: 'mnuReportLPBtoPP',
            name: 'mnuReportLPBtoPP',
            component: () => import("@/views/starcon/finance/ReportLPBtoPP.vue"),
          },
          {
            path: 'department-cost',
            name: 'DepartmentCostSms',
            component: () => import("@/views/starcon/finance/DepartmentCost.vue"),
          },
          {
            path: '/admin/sms/CashFlow',
            name: 'Cash Flow',
            component: () => import("@/views/starcon/finance/CashFlow.vue")
          },
          {
            path: 'credit-limit-usage',
            name: 'CreditLimitUsageSms',
            component: () => import("../views/starcon/finance/CreditLimitUsage.vue"),
          },
          {
            path: '/admin/sms/Hutang',
            name: 'Hutangs',
            component: () => import("@/views/starcon/finance/Hutang.vue")
          },
          {
            path: '/admin/sms/Piutang',
            name: 'Piutangainamo',
            component: () => import("@/views/starcon/finance/Piutangainamo.vue")
          },
          {
            path: 'purchasing-invoice-analysis',
            name: 'PurchasingInvAnalysisSms',
            component: () => import("@/views/starcon/finance/InvoiceAnalysis.vue"),
          },
          {
            path: 'purchasing-payment-invoice-analysis',
            name: 'PurchasingPaymentInvAnalysisSms',
            component: () => import("@/views/starcon/finance/PurchasingPaymentInvoiceAnalysis.vue"),
          },
          {
            path: 'purchasing-analysis',
            name: 'PurchasingAnalysisSms',
            component: () => import("@/views/starcon/finance/PurchasingAnalysis.vue"),
          },
        ]
      },
      {
        path: "maintenance",
        component: Children,
        redirect: "/admin/sms/maintenance/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'master-section',
            name: 'master-section-SMS',
            component: () => import("@/views/starcon/maintenance/MasterSection.vue"),
          },
          {
            path: 'master-part-of-mach',
            name: 'MasterPartofMachStarcon',
            component: () => import("@/views/starcon/maintenance/MasterPartofMach.vue")
          },    
          {
            path: 'master-checklist',
            name: 'MasterMaintenanceStarcon',
            component: () => import("@/views/starcon/maintenance/MasterMaintenance.vue")
          },  
          {
            path: 'generate-qr-code',
            name: 'GenerateQRMaintenanceStarcon',
            component: () => import("@/views/starcon/maintenance/GenerateQr.vue")
          },   
          {
            path: 'create',
            name: 'CreateMaintenanceStarcon',
            component: () => import("@/views/starcon/maintenance/Create.vue")
          },         
        ]
      },
    ]
  },

  //WBJ
  {
    path: "/admin/wbj",
    component: Master,
    redirect: "/admin/wbj/dashboard",
    beforeEnter: checkAuth,
    name: "WBJ",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewWbj',
        component: () => import("@/views/wbj/Overview.vue")
      },
      {
        path: "approval",
        component: Children,
        redirect: "/admin/wbj/approval/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'purchase-request',
            name: 'PurchaseRequestWbj',
            component: () => import("@/views/wbj/approval/PurchaseRequest.vue")
          },
          {
            path: 'purchase-order',
            name: 'PurchaseOrderWbj',
            component: () => import("@/views/wbj/approval/PurchaseOrder.vue")
          },
        ]
      }
    ]
  },

  //Sunrise Mill
  {
    path: "/admin/sm",
    component: Master,
    redirect: "/admin/sm/dashboard",
    beforeEnter: checkAuth,
    name: "SM",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewSm',
        component: import("@/views/sm/Overview.vue")
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OverviewSm',
            name: 'OrderDashboard',
            component: () => import("@/views/sm/dashboard/OverviewSm.vue"),
          },
          {
            path: 'trend-production',
            name: 'TrendProductionSm',
            component: () => import("@/views/sm/dashboard/TrendProduction.vue"),
          },
        ]
      },
      {
        path: "production",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          // {
          //   path: 'OverviewProd',
          //   name: 'OrderDashboard',
          //   component: () => import("@/views/sm/dashboard/OverviewSm.vue"),
          // },
          {
            path: 'hrc-origin-analysis',
            name: 'HrcOriginAnalysisSm',
            component: () => import("@/views/sm/production/HrcByOriginAnalysis.vue"),
          },
          {
            path: 'OffGaugeAnalysis',
            name: 'smOffGaugeAnalysis',
            component: () => import("@/views/sm/production/OffGaugeAnalysis.vue"),
          },
          {
            path: 'letter-of-complaint',
            name: 'smLetterOfComplaint',
            component: () => import("@/views/sm/production/LetterOfComplaint.vue"),
          },
          {
            path: 'letter-of-complaint',
            name: 'smLetterOfComplaint',
            component: () => import("@/views/sm/production/LetterOfComplaint.vue"),
          },
          {
            path: 'defect-analysis',
            name: 'smDefectAnalysis',
            component: () => import("@/views/sm/production/DefectAnalysis.vue"),
          },
        ]
      },
      {
        path: "delivery",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'order-to-shipment-analysis',
            name: 'OrderToShipmentAnalysisSm',
            component: () => import("@/views/sm/delivery/OrderToShipmentAnalysis.vue"),
          },
          {
            path: 'DeliveryReportAnalysis',
            name: 'DeliveryReportAnalysisSM',
            component: () => import("@/views/sm/delivery/DeliveryReportAnalysis.vue"),
          },
        ]
      },
      {
        path: "finance",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'purchasing-payment-invoice-analysis',
            name: 'PurchasingPaymentInvAnalysisSm',
            component: () => import("@/views/sm/finance/PurchasingPaymentInvoiceAnalysis.vue"),
          },
        ]
      },
      {
        path: "production",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'AvgYielOriginAnalysis',
            name: 'AvgYielOriginAnalysisSM',
            component: () => import("@/views/sm/AvgYieldOriginAnalysis.vue"),
          },
          {
            path: 'material-consumption',
            name: 'mnuMaterialConsumptionSr',
            component: () => import("@/views/sm/production/MaterialConsumption.vue"),
          },
        ]
      },
      {
        path: "customer-order",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'customer-history',
            name: 'mnSmCustomerHistory',
            component: () => import("@/views/sm/order/CustomerHistory.vue"),
          },
          {
            path: 'sales-contract-ela',
            name: 'SalesContractReportEla',
            component: () => import("@/views/sm/report/SalesContractReportEla.vue"),
          },
          {
            path: 'customer-complain',
            name: 'mnSmCustomerComplain',
            component: () => import("@/views/sm/order/CustomerComplain.vue"),
          },
          {
            path: 'order-status',
            name: 'mnOrderStatus',
            component: () => import("@/views/sm/order/SalesOrderStatus.vue"),
          },
          {
            path: 'tracking-order',
            name: 'mnTrackingOrder',
            component: () => import("@/views/sm/order/TrackingOrder.vue"),
          },
        ]
      },
      {
        path: "finance-report",
        component: Children,
        redirect: "/admin/sm/finance-report",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: '/admin/sm/financePO',
            name: 'finance-report',
            component: () => import("@/views/sm/finance/PurcahseOrder.vue"),
          },
          {
            path: '/admin/sm/CashFlow',
            name: 'CashFlow',
            component: () => import("@/views/sm/finance/CashFloww.vue"),
          },

          {
            path: '/admin/sm/Hutang',
            name: 'Hutang',
            component: () => import("@/views/sm/finance/Hutang.vue"),
          },   

          {
            path: '/admin/sm/Piutang',
            name: 'CashFlows',
            component: () => import("@/views/sm/finance/Piutang.vue"),
          }      
        
        ]
      },
      {
        path: "approval",
        component: Children,
        redirect: "/admin/sm/approval/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'pru',
            name: 'PruApprovalSm',
            component: () => import("@/views/sm/approval/PruApprovalSm.vue")
          },
          {
            path: 'do',
            name: 'DoApprovalSm',
            component: () => import("@/views/sm/approval/DoApprovalSm.vue")
          },
          {
            path: 'pr',
            name: 'PrApprovalSm',
            component: () => import("@/views/sm/approval/PrApprovalSm.vue")
          },
          {
            path: 'ppp',
            name: 'pppApprovalSM',
            component: () => import("@/views/sm/approval/pppApproval.vue"),
          },
          {
            path: 'pr-detail',
            name: 'DetailPRSm',
            component: () => import("@/views/sm/finance/DetailPR.vue"),
          },
        ]
      },
      {
        path: "hris",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'attendance',
            name: 'attendanceSr',
            component: () => import("@/views/sm/hris/Attendance.vue"),
          },
          {
            path: 'overview',
            name: 'overviewHrisSr',
            component: () => import("@/views/sm/hris/Overview.vue"),
          },
          {
            path: 'annual',
            name: 'AnnualLeaveSM',
            component: () => import("@/views/sm/hris/AnnualLeave.vue"),
          },
        ]
      },
      {
        path: "hrp-report",
        component: Children,
        redirect: "/admin/sm/hrp-report",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'hrp-procurement',
            name: 'hrpProcurement',
            component: () => import("@/views/sm/hrp/hrpProcurement.vue"),
          },
          {
            path: 'availability',
            name: 'availabilitySM',
            component: () => import("@/views/sm/CoilAvailability.vue"),
          },
          {
            path: 'origin',
            name: 'originSM',
            component: () => import("@/views/sm/hrp/origin.vue"),
          },
        ]
      },
      {
        path: "shipment-analysis",
        component: Children,
        redirect: "/admin/sm/shipment-analysis",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'analysis',
            name: 'analysis',
            component: () => import("@/views/sm/shipment/P3.vue"),
          }
          
        ]
      },
      // {
      //   path: "analysis",
      //   component: Children,
      //   redirect: "/admin/sm/shipment-analysis",
      //   beforeEnter: checkAuthAccessMenuUser,
      //   children: [
      //     {
      //       path: 'analysis',
      //       name: 'analysis',
      //       component: () => import("@/views/sm/shipment/P3.vue"),
      //     }
      //   ]
      // },
      {
        path: "analysis",
        component: Children,
        redirect: "/admin/sm/analysis",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'thickness-composition',
            name: 'thicknessCompositionSM',
            component: () => import("@/views/sm/analysis/ThicknessComposition.vue"),
          },
          {
            path: 'production',
            name: 'ProductionAnalysisSM',
            component: () => import("@/views/sm/analysis/Production.vue"),
          },
          {
            path: 'rolling-report',
            name: 'RollingReportSM',
            component: () => import("@/views/sm/analysis/RollingReport.vue"),
          },
        ]
      },
      {
        path: "sales-analysis",
        component: Children,
        redirect: "/admin/sm/sales-analysis",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'sc-fg-production',
            name: 'sales-analysis',
            component: () => import("@/views/sm/sales/SCFGProdAnalysis.vue"),
          },
        ]
      },
      {
        path: "customer-order",
        component: Children,
        redirect: "/admin/sm/customer-order",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'SalesContractReport',
            name: 'SalesContractReportSM',
            component: () => import("@/views/sm/SalesContractReport.vue"),
          },
        ]
      },
      {
        path: "maintenance",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'create',
            name: 'createMaintenanceSm',
            component: () => import("@/views/sm/maintenance/Create.vue"),
          },
          {
            path: 'index',
            name: 'indexMaintenanceSm',
            component: () => import("@/views/sm/maintenance/Index.vue"),
          },
          {
            path: 'generate',
            name: 'GenerateQrCodeMaintenanceSm',
            component: () => import("@/views/sm/maintenance/GenerateQrCode.vue"),
          },
          {
            path: 'Trend',
            name: 'MaintenanceTrendSm',
            component: () => import("@/views/sm/maintenance/Trend.vue"),
          },
          {
            path: 'approval_config',
            name: 'woApprovalConfigSm',
            props:{ companyId: 'SM'},
            component: () => import("@/views/viva/maintenance/MtWoApprovalConfigView.vue"),
          },
          {
            path: 'planned',
            name: 'mnSmPlannedMtc',
            // TODO jangan lupa
            props:{ companyId: 'SM', maintenanceType:'P'},
            component: () => import("@/views/viva/maintenance/MtWoHeader.vue"),
          },
        ]
      },
      {
        path: "purchasing",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'purchase-perfomance',
            name: 'smPurchasingPerfomance',
            component: () => import("@/views/sm/purchasing/PurchasingPerfomance.vue"),
          }
        ]
      }
    ]
  },

  { path: "*", 
    // component: DashboardLayout,
    component: () => import("@/components/Layouts/Default_Dashboard.vue"),
    redirect: "/PageNotFound",
    beforeEnter: checkAuth,
    children: [
      {
        path: 'PageNotFound',
        name: 'PageNotFound',
        component: () => import("@/views/PageNotFound.vue")
      }
    ]
  },

  //KMB
  {
    path: "/admin/kmb",
    component: Master,
    redirect: "/admin/kmb/dashboard",
    beforeEnter: checkAuth,
    name: "KMB",
    children: [
      {
        path: "development",
        component: Children,
        redirect: "/admin/kmb/development",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'test1',
            name: 'test1',
            component: () => import("@/views/kmb/testPage.vue"),
          },
        ]
      },
      {
        path: 'dashboard',
        name: 'OverviewKMB',
        component: () => import("@/views/kmb/Overview.vue"),
      },
      {
        path: "dashboard",
        component: Children,
        redirect: "/admin/kmb/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'AnalyticDashboard',
            name: 'KmbAnalyticDashboard',
            component: () => import("@/views/kmb/AnalyticDashboard.vue"),
          },
          {
            path: 'OrderDashboard',
            name: 'KmbOrderDashboard',
            component: () => import("@/views/kmb/order/OrderDashboard.vue"),
          },
          {
            path: 'OrderDashboardByDepo',
            name: 'KmbOrderDashboardByDepo',
            component: () => import("@/views/kmb/order/OrderDashboardByDepo.vue"),
          },
          {
            path: 'GISAnalysis',
            name: 'GISAnalysisKMB',
            component: () => import("@/views/kmb/GISAnalysis"),
          },
          {
            path: 'CustGISAnalysis',
            name: 'CustGISAnalysisKMB',
            component: () => import("@/views/kmb/CustGISAnalysis"),
          },
          {
            path: 'OutletRatio',
            name: 'OutletRatioKMB',
            component: () => import("@/views/kmb/OutletRatio"),
          },
        ]
      },
      {
        path: 'analysis',
        component: Children,
        redirect: "/admin/kmb/analysis",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OrderAnalysis',
            name: 'OrderAnalysis',
            component: () => import("@/views/kmb/OrderAnalysis.vue"),
          },
          {
            path: 'InvoiceAnalysis',
            name: 'InvoiceAnalysis',
            component: () => import("@/views/kmb/InvoiceAnalysis.vue"),
          },
          {
            path: 'ShippingAnalysis',
            name: 'ShippingAnalysis',
            component: () => import("@/views/kmb/ShippingAnalysis.vue"),
          },
          {
            path: 'PurchaseAnalysis',
            name: 'PurchaseAnalysis',
            component: () => import("@/views/kmb/PurchaseAnalysis.vue"),
          },
          {
            path: 'KPIAnalysis',
            name: 'KPIAnalysis',
            component: () => import("@/views/kmb/KPIAnalysis.vue"),
          },
          {
            path: 'OrderShipPerformance',
            name: 'OrderShipPerformance',
            component: () => import("@/views/kmb/OrderShipPerformance.vue"),
          },
          {
            path: 'KPIAchiveAdmin',
            name: 'KPIAchiveAdminKMB',
            component: () => import("@/views/kmb/analysis/KPIAchiveAdmin.vue"),
          },
          {
            path: 'OrderCancelAnalysis',
            name: 'OrderCancelAnalysisKMB',
            component: () => import("@/views/kmb/OrderCancelAnalysis.vue"),
          },
          {
            path: 'OrderAnalysisSC',
            name: 'OrderAnalysisSC_KMB',
            component: () => import("@/views/kmb/OrderAnalysisSC.vue"),
          },
          {
            path: 'comparison_price',
            name: 'mnHPHKmb',
            component:() => import("@/views/kmb/approval/HPHApproval.vue"),
          },
        ]
      },
      {
        path: "e-reports",
        component: Children,
        redirect: "/admin/kmb/e-reports",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'DebtReport',
            name: 'DebtReport',
            component: () => import("@/views/kmb/DebtReport.vue"),
          },
          {
            path: 'CostRatioKMB',
            name: 'CostRatioKMB',
            component: () => import("@/views/kmb/CostRatio.vue"),
          },
          {
            path: 'CostWeightRatioKMB',
            name: 'CostWeightRatioKMB',
            component: () => import("@/views/kmb/CostWeightRatio.vue"),
          },
          {
            path: 'PiutangReport',
            name: 'PiutangReports',
            component: () => import("@/views/kmb/PiutangReport.vue"),
          },
          {
            path: 'OrderReport',
            name: 'OrderReport',
            component: () => import("@/views/kmb/OrderReport.vue"),
          },
          {
            path: 'ShippingReport',
            name: 'ShippingReport',
            component: () => import("@/views/kmb/ShippingReport.vue"),
          },
          {
            path: 'MaterialAvailReport',
            name: 'MaterialAvailReport',
            component: () => import("@/views/kmb/MaterialAvailReport.vue"),
          },
          {
            path: 'MachineBackLog',
            name: 'MachineBackLog',
            component: () => import("@/views/kka/production/MachineBacklog.vue"),
          },
          {
            path: 'DepoStockReport',
            name: 'DepoStockReport',
            component: () => import("@/views/kmb/DepoStockReport.vue"),
          },
          {
            path: 'DepoStockValueReport',
            name: 'DepoStockValueReport',
            component: () => import("@/views/kmb/DepoStockValueReport.vue"),
          },
          {
            path: 'OrderShortageReport',
            name: 'OrderShortageReport',
            component: () => import("@/views/kmb/OrderShortageReport.vue"),
          },
          {
            path: 'OrderReadyToShipReport',
            name: 'OrderReadyToShipReport',
            component: () => import("@/views/kmb/OrderReadyToShipReport.vue"),
          },
          {
            path: 'OutstandingInv',
            name: 'Outstanding',
            component: () => import("@/views/kmb/OutStandingInvoice.vue"),
          },
          {
            path: 'customer-history',
            name: 'CustomerHistoryKMB',
            component: () => import("@/views/kmb/report/CustomerHistory.vue"),
          },
          {
            path: 'hutang',
            name: 'hutangKMB',
            component: () => import("@/views/kmb/finance/Hutang.vue"),
          },
          {
            path: 'collection-letter',
            name: 'collectionLetterKMB',
            component: () => import("@/views/kmb/finance/CollectionLetter.vue"),
          },
          {
            path: 'stockMoving',
            name: 'stockMovingKMB',
            component: () => import("@/views/kmb/StockMoving.vue"),
          },
          {
            path: 'customer-growth',
            name: 'CustomerGrowthKMB',
            component: () => import("@/views/kmb/order/CustomerGrowth.vue"),
          },
        ]
      },
      {
        path: "mpf",
        component: Children,
        redirect: "/admin/kmb/mpf",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
            {
              path: 'MPFList',
              name: 'MPFListKMB',
              component: () => import("@/views/kmb/MPFList.vue"),
            },
            {
              path: 'MPFSent',
              name: 'MPFSentKMB',
              component: () => import("@/views/kmb/MPFSent.vue"),
            },
          ]
      },
      {
        path: "SalesActivity",
        component: Children,
        redirect: "/admin/kmb/SalesActivity",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
            {
              path: 'CustomerVisit',
              name: 'CustomerVisit',
              component: () => import("@/views/kmb/CustomerVisit.vue"),
            },
            {
              path: 'TodayVisit',
              name: 'TodayVisit',
              component: () => import("@/views/kmb/TodayVisit.vue"),
            },
            {
              path: 'VisitReport',
              name: 'VisitReport',
              component: () => import("@/views/kmb/VisitReport.vue"),
            },
            {
              path: 'Telemarketing',
              name: 'Telemarketing',
              component: () => import("@/views/kmb/Telemarketing.vue"),
            },
            {
              path: 'DriverActivity',
              name: 'DriverActivityKMB',
              component: () => import("@/views/kmb/DriverActivityReport.vue"),
            },
          ]
      },
      {
        path: "ProjectManagement",
        component: Children,
        redirect: "/admin/kmb/ProjectManagement",
        beforeEnter: checkAuth,
        children: [
          {
            path: 'ProjectTracking',
            name: 'ProjectTracking',
            component: () => import("@/views/kmb/ProjectTracking.vue"),
          },
          {
            path: 'ProjectDashboard',
            name: 'ProjectDashboard',
            component: () => import("@/views/kmb/ProjectDashboard.vue"),
          },
          {
            path: 'ProjectReport',
            name: 'ProjectReport',
            component: () => import("@/views/kmb/ProjectReport.vue"),
          },
          {
            path: 'ProjectDiscussion',
            name: 'ProjectDiscussionKMB',
            component: () => import("@/views/kmb/ProjectDiscussion.vue"),
          },
        ]
      },
      {
        path: "CustomerOrder",
        component: Children,
        redirect: "/admin/kmb/CustomerOrder",
        beforeEnter: checkAuth,
        children: [
          {
            path: 'CreateOrder',
            name: 'CreateOrder',
            component: () => import("@/views/kmb/CreateOrder.vue"),
          },
          {
            path: 'OrderList',
            name: 'OrderList',
            component: () => import("@/views/kmb/OrderList.vue"),
          },
          {
            path: 'OrderCancel',
            name: 'OrderCancel',
            component: () => import("@/views/kmb/OrderCancel.vue"),
          },
          {
            path: 'OrderCancelList',
            name: 'OrderCancelList',
            component: () => import("@/views/kmb/OrderCancelList.vue"),
          },
        ]
      },
      {
        path: "hris",
        component: Children,
        redirect: "/admin/kmb/hris",
        beforeEnter: checkAuth,
        children: [
          {
            path: 'Overview',
            name: 'OverviewHrisKMB',
            component: () => import("@/views/kmb/OverviewHRIS.vue"),
          },
          {
            path: 'AttendanceReport',
            name: 'AttendanceReport',
            component: () => import("@/views/kmb/AttendanceReportKMB.vue"),
          },
          {
            path: '/admin/kmb/hris/Employee',
            name: 'Employeess',
            component: () => import("@/views/kmb/Employee.vue"),
          },
          {
            path: '/admin/kmb/hris/Permission',
            name: 'Permissions',
            component: () => import("@/views/kmb/Permission.vue"),
          },
        ]
      },
      {
        path: "branch-perfomance",
        component: Children,
        redirect: "/admin/kmb/branch-perfomance",
        beforeEnter: checkAuth,
        children: [
          {
            path: 'sales-procurement',
            name: 'SalesProcurement',
            component: () => import("@/views/kmb/branch_perfomance/SalesProcurement.vue"),
          },
          {
            path: 'bad-customer',
            name: 'BadCustomer',
            component: () => import("@/views/kmb/branch_perfomance/BadCustomer.vue"),
          },
          // {
          //   path: 'AttendanceReport',
          //   name: 'AttendanceReport',
          //   component: AttendanceReportKMB
          // },
        ]
      },
      {
        path: "Occupancy",
        component: Children,
        redirect: "/admin/kmb/Occupancy",
        beforeEnter: checkAuth,
        children: [
          {
            path: 'Occupancy',
            name: 'Occupancy',
            component: () => import("@/views/kmb/occupancy/DepoOccupancy.vue"),
          },
          {
            path: 'stock-occupancy',
            name: 'stock-occupancy',
            component: () => import("@/views/kmb/occupancy/StockOccupancy.vue"),
          },
        
          // {
          //   path: 'AttendanceReport',
          //   name: 'AttendanceReport',
          //   component: AttendanceReportKMB
          // },
        ]
      },
      {
        path: "/printSP/:book_id",
        name: "printSP",
        component: () =>
          import("@/views/kmb/printSP.vue"),
      },
      {
        path: "/printMPF/:id",
        name: "printMPF",
        component: () =>
          import("@/views/viva/MPFPrint.vue"),
      },
      {
        path: "transaction",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'sales-order',
            name: 'SalesOrderKMB',
            component: () => import("@/views/kmb/transaction/sales_order/SalesOrder.vue"),
          },
          {
            path: 'sales-order/create',
            name: 'SalesOrderCreateKMB',
            component: () => import("@/views/kmb/transaction/sales_order/SalesOrderCreate.vue"),
          },
        ]
      },
      {
        path: "driver-activity",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'AssignShipment',
            name: 'AssignShipmentKMB',
            component: () => import("@/views/kmb/AssignShipment.vue"),
          },
        ]
      },
      {
        path: "approval",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'comparison_price',
            name: 'mnHPHKmb',
            component:() => import("@/views/kmb/approval/HPHApproval.vue"),
          },
        ]
      },
      
      // {
      //   path: "driver",
      //   component: Children,
      //   redirect: "/admin/kmb/driver",
      //   beforeEnter: checkAuthAccessMenuUser,
      //   children: [
      //       {
      //         path: 'DriverActivity',
      //         name: 'DriverActivity',
      //         component: () => import("@/views/kmb/DriverActivityReport.vue"),
      //       },
      //     ]
      // },
    ]
  },

  //KBT
  {
    path: "/admin/kbt",
    component: Master,
    redirect: "/admin/kbt/dashboard",
    beforeEnter: checkAuth,
    name: "KBT",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewKbt',
        component: () => import("@/views/kbt/Overview.vue")
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'order',
            name: 'OrderDashboards',
            component: () => import("@/views/kbt/dashboard/OverviewKbt.vue"),
          },
          {
            path: 'GISAnalysis',
            name: 'GISAnalysisKBT',
            component: () => import("@/views/kbt/GISAnalysis"),
          },
        ]
      },
      {
        path: "report",
        component: Children,
        redirect: "/admin/kbt/report/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'material-availability',
            name: 'MaterialAvailability',
            component: () => import("@/views/kbt/report/MaterialAvailability.vue"),
          },
          {
            path: 'piutang',
            name: 'PiutangReportKbt',
            component: () => import("@/views/kbt/report/PiutangReport.vue"),
          },
          {
            path: 'hutang',
            name: 'DebtReportKbt',
            component: () => import("@/views/kbt/report/DebtReport.vue"),
          },
          {
            path: 'order',
            name: 'OrderReportKbt',
            component: () => import("@/views/kbt/report/OrderReport.vue"),
          },
          {
            path: 'CostRatioKBT',
            name: 'CostRatioKBTS',
            component: () => import("@/views/kbt/report/CostRatio.vue")
          }
        ]
      },

      {
        path: "e-reports",
        component: Children,
        redirect: "/admin/kbt/e-reports",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'CostWeightRatioKBT',
            name: 'CostWeightRatioKBT',
            component: () => import("@/views/kbt/CostWeightRatio.vue"),
          },
        ]
      },

      {
        path: "analyze",
        component: Children,
        redirect: "/admin/kbt/analyze/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'order-performance',
            name: 'OrdertoShipPerformance',
            component: () => import("@/views/kbt/analyze/OrdertoShipPerformance.vue"),
          },
        ]
      },
      {
        path: "sales-activity",
        component: Children,
        redirect: "/admin/kbt/sales-activity/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'customer-visit',
            name: 'CustomerVisitKbt',
            component: () => import("@/views/kbt/sales_activity/CustomerVisitKbt.vue"),
          },
          {
            path: 'today-visit',
            name: 'TodayVisitKbt',
            component: () => import("@/views/kbt/sales_activity/TodayVisitKbt.vue"),
          },
          {
            path: 'activity-report',
            name: 'ActivityReportKbt',
            component: () => import("@/views/kbt/sales_activity/ActivityReportKbt.vue"),
          }
        ]
      },
      {
        path: "hris",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'attendance',
            name: 'attendanceKbt',
            component: () => import("@/views/kbt/hris/Attendance.vue"),
          },
          {
            path: 'overview',
            name: 'overviewHrisKbt',
            component: () => import("@/views/kbt/hris/Overview.vue"),
          },
          {
            path: 'annual',
            name: 'annualLeaveKbt',
            component: () => import("@/views/kbt/hris/AnnualLeave.vue"),
          },
        ]
      },
      {
        path: "order",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'customer-history',
            name: 'CustomerHistoryKbt',
            component: () => import("@/views/kbt/order/CustomerHistory.vue"),
          },
        ]
      }
    ]
  },

  //Tobang
  {
    path: "/admin/tobang",
    component: Master,
    redirect: "/admin/tobang/dashboard",
    beforeEnter: checkAuth,
    name: "TOBANG",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewTobang',
        component: () => import("@/views/tobang/Overview.vue")
      },
      {
        path: "sales-activity",
        component: Children,
        redirect: "/admin/tobang/sales-activity/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'customer-visit',
            name: 'CustomerVisitTobang',
            component: () => import("@/views/tobang/sales_activity/CustomerVisitTobang.vue"),
          },
          {
            path: 'today-visit',
            name: 'TodayVisitTobang',
            component: () => import("@/views/tobang/sales_activity/TodayVisitTobang.vue"),
          },
          {
            path: 'activity-report',
            name: 'ActivityReportTobang',
            component: () => import("@/views/tobang/sales_activity/ActivityReportTobang.vue"),
          }
        ]
      },
    ]
  },

  //Sunrise Steel 
  {
    path: "/admin/sr",
    component: Master,
    redirect: "/admin/sr/dashboard",
    beforeEnter: checkAuth,
    name: "SR",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewSr',
        component: () => import("@/views/sr/OverviewSr.vue")
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'live-production',
            name: 'LiveDataProduction',
            component: () => import("@/views/sr/dashboard/LiveDataProduction.vue"),
          },
          {
            path: 'block-schedule',
            name: 'BlockSchedule',
            component: () => import("@/views/sr/dashboard/BlockSchedule.vue"),
          },
          {
            path: 'production',
            name: 'ProductionSummary',
            component: () => import("@/views/sr/dashboard/ProductionSummary.vue"),
          },
          {
            path: 'Slitting',
            name: 'Slitting',
            component: () => import("@/views/sr/dashboard/ProductionSlitting.vue"),
          },
          {
            path: 'trend-production',
            name: 'srDashboardProdTrend',
            component: () => import("@/views/sr/dashboard/TrendProduction.vue"),
          },
          {
            path: 'GISAnalysis',
            name: 'GISAnalysisSR',
            component: () => import("@/views/sr/GISAnalysis"),
          },
        ]
      },
      {
        path: "mill-analysis",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'production-analysis',
            name: 'ProductionAnalyisis',
            component: () => import("@/views/sr/mill/ProductionAnalyisis.vue"),
          },
          {
            path: 'thickness-composition',
            name: 'ThicknessComposition',
            component: () => import("@/views/sr/mill/ThicknessComposition.vue"),
          },
          {
            path: 'downtime-analysis',
            name: 'downtimeanalysis',
            component: () => import("@/views/sr/mill/Downtime.vue"),
          },
          {
            path: 'defect-analysis',
            name: 'DefectAnalysis',
            component: () => import("@/views/sr/mill/DefectAnalysis.vue"),
          },
        ]
      },
      {
        path: "shipment-analysis",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'list-of-ppp',
            name: 'ListOfPPP',
            component: () => import("@/views/sr/shipmentAnalysis/ListOfPPP.vue"),
          }
        ]
      },
      {
        path: "finance",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: '/admin/sr/PurchaseOrder',
            name: 'purchase order',
            component: () => import("@/views/sr/finance/PurchaseorderSR.vue")
          },
          {
            path: '/admin/sr/Hutang',
            name: 'Hutangs',
            component: () => import("@/views/sr/finance/Hutang.vue")
          },
          {
            path: '/admin/sr/Piutang',
            name: 'Piutangainamo',
            component: () => import("@/views/sr/finance/Piutangainamo.vue")
          },
          {
            path: '/admin/sr/CashFlow',
            name: 'Cash Flow',
            component: () => import("@/views/sr/finance/CashFlow.vue")
          },
          {
            path: 'purchasing-analysis',
            name: 'PurchasingAnalysisSr',
            component: () => import("@/views/sr/finance/PurchasingAnalysis.vue"),
          },
          {
            path: 'department-cost',
            name: 'DepartmentCostSr',
            component: () => import("@/views/sr/finance/DepartmentCost.vue"),
          },
          {
            path: 'purchasing-invoice-analysis',
            name: 'PurchasingInvAnalysisSr',
            component: () => import("@/views/sr/finance/InvoiceAnalysis.vue"),
          },
          {
            path: 'purchasing-payment-invoice-analysis',
            name: 'PurchasingPaymentInvAnalysisSr',
            component: () => import("@/views/sr/finance/PurchasingPaymentInvoiceAnalysis.vue"),
          },
          {
            path: 'credit-limit-usage',
            name: 'CreditLimitUsageSr',
            component: () => import("../views/sr/finance/CreditLimitUsage.vue"),
          },
        ]
      },
      {
        path: "Finance Approval",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: '/admin/sr/FinanceApproval',
            name: 'PR Approval',
            component: () => import("@/views/sr/financeApproval/PrApproval.vue")
          },
          {
            path: '/admin/sr/POReturApproval/',
            name: 'PO Retur Approval',
            component: () => import("@/views/sr/financeApproval/POReturApproval.vue")
          },
        ]
      },
      {
        path: "production-report",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'free-coil',
            name: 'FreeCoilReport',
            component: () => import("@/views/sr/report/FreeCoilReport.vue"),
          },
          {
            path: 'crc-avail',
            name: 'CrcAvailability',
            component: () => import("@/views/sr/report/CrcAvailability.vue"),
          },
          {
            path: 'sales-contract',
            name: 'SalesContractReport',
            component: () => import("@/views/sr/report/SalesContractReport.vue"),
          },
          {
            path: 'sales-contract-ela',
            name: 'SalesContractReportEla',
            component: () => import("@/views/sr/report/SalesContractReportEla.vue"),
          },
          {
            path: 'crc-procurement',
            name: 'CrcProcurement',
            component: () => import("@/views/sr/report/CrcProcurement.vue"),
          },
          {
            path: 'sales-order-status',
            name: 'SalesOrderStatusSr',
            component: () => import("@/views/sr/report/SalesOrderStatus.vue"),
          },
          {
            path: 'crc-avail-daily',
            name: 'CrcAvailibilityDaily',
            component: () => import("@/views/sr/report/CrcAvailabilityDaily.vue"),
          },
          {
            path: 'az-availability',
            name: 'AzAvailibility',
            component: () => import("@/views/sr/crcCoilAndAz/AlluZincAvailability.vue"),
          },
          {
            path: 'KKAConsigneeSC',
            name: 'KKAConsigneeSC_SR',
            component: () => import("@/views/sr/KKAConsigneeSC.vue"),
          },
          {
            path: 'CoilAvailability',
            name: 'CoilAvailabilitySR',
            component: () => import("@/views/sr/CoilAvailability"),
          },
          {
            path: 'entry-section',
            name: 'EntrySectionSR',
            component: () => import("@/views/sr/report/EntrySection.vue"),
          },
          {
            path: 'crc-arrival-estimation',
            name: 'CrcArrivalEstimationSR',
            component: () => import("@/views/sr/report/CrcArrivalEstimation.vue"),
          }
        ]
      },
      {
        path: "sales",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'analysis',
            name: 'SCDispatchAnalysis',
            component: () => import("@/views/sr/sales/SCDispatchAnalysis.vue"),
          },
          {
            path: 'sc-fg-production',
            name: 'SCProdAnalysis',
            component: () => import("@/views/sr/sales/SCProdAnalysis.vue"),
          },
          {
            path: 'ListOfComplain',
            name: 'ListOfComplainSR',
            component: () => import("@/views/sr/ListOfComplain.vue"),
          },
          {
            path: 'sc-production',
            name: 'SCFGProdAnalysis',
            component: () => import("@/views/sr/sales/SCFGProdAnalysis.vue"),
          },
        ]
      },
      {
        path: "customer-order",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'tracking-order',
            name: 'TrackingOrderSR',
            component: () => import("@/views/sr/order/TrackingOrder.vue"),
          },
          {
            path: 'customer-history',
            name: 'CustomerHistorySR',
            component: () => import("@/views/sr/order/CustomerHistory.vue"),
          }
        ]
      },
      {
        path: "sales-activity",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'activity-report',
            name: 'activityReportSr',
            component: () => import("@/views/sr/sales/ActivityReport.vue"),
          },
          {
            path: 'customer-visit',
            name: 'CustomerVisitSr',
            component: () => import("@/views/sr/sales/CustomerVisit.vue"),
          },
          {
            path: 'today',
            name: 'TodayVisitSr',
            component: () => import("@/views/sr/sales/TodayVisit.vue"),
          }    
        ]
      },
      {
        path: "project",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'project-tracking',
            name: 'projectTrackingSr',
            props:{ isCreate: true},
            component: () => import("@/views/sr/project/ProjectTracking.vue"),
          },
          {
            path: 'project-reporting',
            name: 'projectReportingSr',
            props:{ isCreate: false},
            component: () => import("@/views/sr/project/ProjectTracking.vue"),
          },
          {
            path: 'Partner',
            name: 'Master-Partner',
            component: () => import("@/views/sr/project/MasterPartner.vue"),
          }

        ]
      },
      {
        path: "merchandiser-activity",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'master-merchandiser',
            name: 'MerchandiserSr',
            component: () => import("@/views/sr/sales/merchandiser/Merchandiser.vue"),
          },
          {
            path: 'activity-report',
            name: 'MerchandiserIndexSr',
            component: () => import("@/views/sr/sales/merchandiser/Index.vue"),
          },
          {
            path: 'today-visit',
            name: 'MerchandiserTodayVisitSr',
            component: () => import("@/views/sr/sales/merchandiser/TodayVisit.vue"),
          },
          {
            path: 'customer-visit',
            name: 'MerchandiserCustomerVisitSr',
            component: () => import("@/views/sr/sales/merchandiser/CustomerVisit.vue"),
          }          
        ]
      },
      {
        path: "mpf",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'list',
            name: 'IndexMPFSr',
            component: () => import("@/views/sr/mpf/Index.vue"),
          },
          {
            path: 'create',
            name: 'CreateMPFSr',
            component: () => import("@/views/sr/mpf/Create.vue"),
          },
        ]
      },
      {
        path: "hris",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'attendance',
            name: 'attendanceSrs',
            component: () => import("@/views/sr/hris/Attendance.vue"),
          },
          {
            path: 'overview',
            name: 'overviewHrisSrs',
            component: () => import("@/views/sr/hris/Overview.vue"),
          },
          {
            path: 'annual',
            name: 'AnnualLeaveSR',
            component: () => import("@/views/sr/hris/AnnualLeave.vue"),
          },
        ]
      },
      {
        path: "approval",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'ppp',
            name: 'pppApprovalSr',
            component: () => import("@/views/sr/financeApproval/pppApproval.vue"),
          },
          {
            path: 'delivery',
            name: 'deliveryApprovalSr',
            component: () => import("@/views/sr/financeApproval/Delivery.vue"),
          },
          {
            path: 'pr-detail',
            name: 'DetailPR',
            component: () => import("@/views/sr/financeApproval/DetailPR.vue"),
          },
          {
            path: 'po-detail',
            name: 'DetailPO',
            component: () => import("@/views/sr/financeApproval/DetailPO.vue"),
          },
        ]
      },
      {
        path: "maintenance",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'create',
            name: 'createMaintenanceSr',
            component: () => import("@/views/sr/maintenance/Create.vue"),
          },
          {
            path: 'index',
            name: 'indexMaintenanceSr',
            component: () => import("@/views/sr/maintenance/Index.vue"),
          },
          {
            path: 'generate',
            name: 'GenerateQrCodeMaintenanceSr',
            component: () => import("@/views/sr/maintenance/GenerateQrCode.vue"),
          },
          {
            path: 'Trend',
            name: 'MaintenanceTrend',
            component: () => import("@/views/sr/maintenance/Trend.vue"),
          },
          {
            path: 'approval_config',
            name: 'woApprovalConfigSr',
            props:{ companyId: 'SR'},
            component: () => import("@/views/viva/maintenance/MtWoApprovalConfigView.vue"),
          },
          {
            path: 'planned',
            name: 'mnSrPlannedMtc',
            props:{ companyId: 'SR', maintenanceType:'SC'},
            component: () => import("@/views/viva/maintenance/MtWoHeader.vue"),
          },
          {
            path: 'unplanned',
            name: 'mnSrUnplannedMtc',
            props:{ companyId: 'SR', maintenanceType:'UN'},
            component: () => import("@/views/viva/maintenance/UpmtWoHeader.vue"),
          },
        ]
      },
      {
        path: "production",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'aluminium-zinc-usage',
            name: 'mnuAluminumZincUsageSr',
            component: () => import("@/views/sr/report/AluminumZincUsage.vue"),
          },
          {
            path: 'aluminium-zinc-receive',
            name: 'mnuAluminumZincReceiveSr',
            component: () => import("@/views/sr/report/AluminiumZincReceive.vue"),
          },
          {
            path: 'material-consumption',
            name: 'mnuMaterialConsumptionSr',
            component: () => import("@/views/sr/production/MaterialConsumption.vue"),
          },
          {
            path: 'OverallEquipmentEffectiveness',
            name: 'OverallEquipmentEffectivenessSR',
            component: () => import("@/views/sr/OverallEquipmentEffectiveness.vue"),
          },
          {
            path: 'query-production',
            name: 'QueryProductionSR',
            component: () => import("@/views/sr/production/QueryProduction.vue"),
          },
          {
            path: 'forecast-material-usage',
            name: 'ForecastMaterialUsage',
            component: () => import("@/views/sr/production/ForecastMaterialUsage.vue"),
          },
          {
            path: 'material-usage-trend',
            name: 'MaterialUsageTrend',
            component: () => import("@/views/sr/production/MaterialUsageTrend.vue"),
          },
          
        ]
      },
      {
        path: "she",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'cbam',
            name: 'SrCibam',
            component: () => import("@/views/sr/production/Cibam.vue"),
          },
        ]
      },
      {
        path: "delivery",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'LeadTimeAnalysis',
            name: 'LeadTimeAnalysisSR',
            component: () => import("@/views/sr/delivery/LeadTimeAnalysis.vue"),
          }
        ]
      },
      {
        path: "purchasing",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'purchase-perfomance',
            name: 'srPurchasingPerfomance',
            component: () => import("@/views/sr/purchasing/PurchasingPerfomance.vue"),
          }
        ]
      }
    ]
  },

  //Ayola Hotel
  {
    path: "/admin/ayala",
    component: Master,
    redirect: "/admin/ayala/dashboard",
    beforeEnter: checkAuth,
    name: "AYOLA",
    props:true,
    children: [
      {
        path: 'dashboard',
        name: 'OverviewAyala',
        component: () => import("@/views/ayala/OverviewAyala.vue")
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'room',
            name: 'RoomStatus',
            component: () => import("@/views/ayala/RoomStatus.vue"),
          }
        ]
      },
      {
        path: "approval",
        component: Children,
        redirect: "/admin/ayala/approval",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'purchase-order',
            name: 'PurchaseOrderAyola',
            component: () => import("@/views/ayala/PurchaseOrder.vue"),
          },
          {
            path: 'purchase-request',
            name: 'PurchaseRequestAyola',
            component: () => import("@/views/ayala/PurchaseRequest.vue"),
          },
        ]
      },
      {
        path: "order",
        component: Children,
        redirect: "/admin/ayala/order",
        beforeEnter: checkAuthAccessMenuUser,
        props:true,
        children: [
          {
            path: 'order',
            name: 'OrderAyola',
            component: () => import("@/views/ayala/orders/order.vue"),
            props:{ whId: 'EM'}
          },
          {
            path: 'topaz-bistro',
            name: 'TopazAyola',
            component: () => import("@/views/ayala/orders/topaz.vue"),
            props:{ whId: 'TS'}
          },
          {
            path: 'kitchen',
            name: 'EmeraldKitchenAyola',
            component: () => import("@/views/ayala/orders/kitchen.vue"),
            props:{ whId: 'EM'}
          },
          {
            path: 'topaz-kitchen',
            name: 'TopazKitchenAyola',
            component: () => import("@/views/ayala/orders/topazKitchen.vue"),
            props:{ whId: 'TS'}
          },
        ]
      },
      {
        path: "report",
        component: Children,
        redirect: "/admin/ayala/report",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'lost-found',
            name: 'LostAndFoundAyola',
            component: () => import("@/views/ayala/LostAndFound.vue"),
          },
        ]
      },
      {
        path: "setting",
        component: Children,
        redirect: "/admin/ayala/setting/scan-image",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'scan-image',
            name: 'ScanImageTextAyola',
            component: () => import("@/views/ayala/setting/ScanImageText.vue"),
          },
        ]
      },
    ]
  },

  //Griya Hotel
  {
    path: "/admin/gk",
    component: Master,
    redirect: "/admin/gk/dashboard",
    beforeEnter: checkAuth,
    name: "GK",
    props:true,
    children:[
      // {
      //   path: 'dashboard',
      //   name: 'OverviewGriya',
      //   component: () => import("@/views/griya/OverviewGriya.vue")
        
      // },
      

      {
        path: "dashboard",
        component: Children,
        children: [
          {
            path: '',
            name: 'OverviewGriya',
            component: () => import("@/views/griya/OverviewGriya.vue")
            
          },
          {
            path: 'Overviewgk',
            name: 'PurchaseOrderGriyas',
            component: () => import("@/views/griya/dashboard/dashboardgk.vue"),
          },
         
        ]
      },  
      {
        path: "approval",
        component: Children,
        redirect: "/admin/gk/approval",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'purchase-order',
            name: 'PurchaseOrderGriya',
            component: () => import("@/views/griya/PurchaseOrder.vue"),
          },
          {
            path: 'purchase-request',
            name: 'PurchaseRequestGriya',
            component: () => import("@/views/griya/PurchaseRequest.vue"),
          },
        ]
      },
      {
        path: "Information",
        component: Children,
        redirect: "/admin/gk/journal",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'jurnal',
            name: 'Jurnal-griya',
            component: () => import("@/views/griya/information/JournalYear"),
          },
          {
            path: 'mutasi',
            name: 'mutasi-finance',
            component: () => import("@/views/griya/information/Mutasi"),
          },
          {
            path: 'Income',
            name: 'Income & Cost List',
            component: () => import("@/views/griya/information/Income"),
          },
          {
            path: 'Occupancy',
            name: 'Occupancys',
            component: () => import("@/views/griya/information/Occupancy"),
          },
        ]
      },
      {
        path: "SalesActivity",
        component: Children,
        redirect: "/admin/gk/SalesActivity",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
            {
              path: 'CustomerVisit',
              name: 'CustomerVisits',
              component: () => import("@/views/griya/salesactivity/CustomerVisit.vue"),
            },
            {
              path: 'TodayVisit',
              name: 'TodayVisits',
              component: () => import("@/views/griya/salesactivity/TodayVisit.vue"),
            },
            {
              path: 'VisitReport',
              name: 'VisitReports',
              component: () => import("@/views/griya/salesactivity/VisitReport.vue"),
            },
          ]
      },
    ]
  },

  //GBRK
  {
    path: "/admin/gbrk",
    component: Master,
    redirect: "/admin/gbrk/dashboard",
    beforeEnter: checkAuth,
    name: "GBRK",
    children:[

        {
          path: 'dashboard',
          name: 'OverviewGBRK',
          component: () => import("@/views/gbrk/OverviewGBRK"),
        },

        {
          path: "dashboard",
          component: Children,
          redirect: "/admin/gbrk/dashboard",
          beforeEnter: checkAuthAccessMenuUser,
          children: [
            {
              path: 'AnalyticDashboard',
              name: 'GBRKAnalyticDashboard',
              component: () => import("@/views/gbrk/AnalyticDashboard"),
            },
            {
              path: 'GISAnalysis',
              name: 'GISAnalysisGBRK',
              component: () => import("@/views/gbrk/GISAnalysis"),
            },
          ]
        },

        {
          path: "reports",
          component: Children,
          redirect: "/admin/gbrk/reports",
          beforeEnter: checkAuthAccessMenuUser,
          children: [
            {
              path: 'CostRatio',
              name: 'CostRatioGBRK',
              component: () => import("@/views/gbrk/CostRatio"),
            },
            {
              path: 'DelivReport',
              name: 'DelivReportGBRK',
              component: () => import("@/views/gbrk/DelivReport"),
            },
            {
              path: 'MaterialAvailability',
              name: 'MaterialAvailabilityGBRK',
              component: () => import("@/views/gbrk/MaterialAvailability"),
            },
            {
              path: 'MillStock',
              name: 'MillStockGBRK',
              component: () => import("@/views/gbrk/MillStock"),
            },
            {
              path: 'OrderShortage',
              name: 'OrderShortageGBRK',
              component: () => import("@/views/gbrk/OrderShortage"),
            },
            {
              path: 'OrderStatus',
              name: 'OrderStatusGBRK',
              component: () => import("@/views/gbrk/OrderStatus"),
            },
            {
              path: 'customer-history',
              name: 'CustomerHistory',
              component: () => import("@/views/gbrk/report/CustomerHistory"),
            },
            {
              path: 'customer-last-order-history',
              name: 'CustomerOrderLastHistoryGbrk',
              component: () => import("@/views/gbrk/report/CustomerOrderLastHistory"),
            },
          ]
        },
        {
          path: "GBRK-Analysis",
          component: Children,
          redirect: "/admin/gbrk/GBRK-Analysis",
          beforeEnter: checkAuthAccessMenuUser,
          children: [
            {
              path: 'OrderAnalysis',
              name: 'OrderAnalysisGBRK',
              component: () => import("@/views/gbrk/OrderAnalysis"),
            },
            {
              path: 'DeliveryAnalysis',
              name: 'DeliveryAnalysisGBRK',
              component: () => import("@/views/gbrk/DeliveryAnalysis"),
            },
            {
              path: 'AverageWeightMeter',
              name: 'AverageWeightMeterGBRK',
              component: () => import("@/views/gbrk/AverageWeightMeter"),
            },
            {
              path: 'OrderToShipPerformance',
              name: 'OrderToShipPerformanceGBRK',
              component: () => import("@/views/gbrk/OrderToShipPerformance"),
            },
            {
              path: 'OrderToShipPerformanceTwo',
              name: 'OrderToShipPerformanceTwoGBRK',
              component: () => import("@/views/gbrk/OrderToShipPerformanceTwo"),
            },
            {
              path: 'QualityPerformance',
              name: 'QualityPerformanceGBRK',
              component: () => import("@/views/gbrk/QualityPerformance"),
            },
            {
              path: 'RawMatBalance',
              name: 'RawMatBalanceGBRK',
              component: () => import("@/views/gbrk/RawMatBalance"),
            },
            {
              path: 'ShippingAnalysis',
              name: 'ShippingAnalysisGBRK',
              component: () => import("@/views/gbrk/ShippingAnalysis"),
            },
          ]
        },

        {
          path: "ProductionPerformance",
          component: Children,
          redirect: "/admin/gbrk/ProductionPerformance",
          beforeEnter: checkAuthAccessMenuUser,
          children: [
            {
              path: 'LoggerVsProd',
              name: 'LoggerVsProdGBRK',
              component: () => import("@/views/gbrk/LoggerVsProd"),
            },
            {
              path: 'CoilRatio',
              name: 'CoilRatioGBRK',
              component: () => import("@/views/gbrk/CoilRatio"),
            },
            {
              path: 'DowntimeAnalysis',
              name: 'DowntimeAnalysisGBRK',
              component: () => import("@/views/gbrk/DowntimeAnalysis"),
            },
            {
              path: 'OrderCapacity',
              name: 'OrderCapacityGBRK',
              component: () => import("@/views/gbrk/OrderCapacity"),
            },
            {
              path: 'OverallEquipmentEffectiveness',
              name: 'OverallEquipmentEffectivenessGBRK',
              component: () => import("@/views/gbrk/OverallEquipmentEffectiveness"),
            },
            {
              path: 'SlittingSummary',
              name: 'SlittingSummaryGBRK',
              component: () => import("@/views/gbrk/SlittingSummary"),
            },
          ]
        },
        {
          path: "Production",
          component: Children,
          redirect: "/admin/gbrk/Production",
          beforeEnter: checkAuthAccessMenuUser,
          children: [
            {
              path: 'MachineBacklog',
              name: 'MachineBacklogGBRK',
              component: () => import("@/views/gbrk/production/MachineBacklog"),
            },
            {
              path: 'order-backlog-analysis',
              name: 'order-backlog-analysis',
              component: () => import("@/views/gbrk/analysis/OrderBacklogAnalysis.vue")
            },
          ]
        },

        {
          path: "SalesActivity",
          component: Children,
          beforeEnter: checkAuthAccessMenuUser,
          children: [
            {
              path: 'CustomerVisit',
              name: 'CustomerVisitGBRK',
              component: () => import("@/views/gbrk/CustomerVisit"),
            },
            {
              path: 'TodayVisit',
              name: 'TodayVisitGBRK',
              component: () => import("@/views/gbrk/TodayVisit"),
            },
            {
              path: 'VisitReport',
              name: 'VisitReportGBRK',
              component: () => import("@/views/gbrk/VisitReport"),
            },
          ]
        },

        {
          path: "Approval",
          component: Children,
          beforeEnter: checkAuthAccessMenuUser,
          children: [
            {
              path: 'PurchaseRequest',
              name: 'PurchaseRequestGBRK',
              component: () => import("@/views/gbrk/PurchaseRequest"),
            },
            {
              path: 'PurchaseAnalysis',
              name: 'PurchaseAnalysisGBRK',
              component: () => import("@/views/gbrk/PurchaseAnalysis"),
            },
            {
              path: 'PiutangReport',
              name: 'PiutangReportGBRK',
              component: () => import("@/views/gbrk/PiutangReport"),
            },
          ]
        },

        {
          path: "e-reports",
          component: Children,
          redirect: "/admin/gbrk/e-reports",
          beforeEnter: checkAuthAccessMenuUser,
          children: [
            {
              path: 'CostWeightRatioGBRK',
              name: 'CostWeightRatioGBRK',
              component: () => import("@/views/gbrk/CostWeightRatio.vue"),
            },
          ]
        },

        {
          path: "finance",
          component: Children,
          beforeEnter: checkAuthAccessMenuUser,
          children: [
            {
              path: 'hutang',
              name: 'hutangGBRK',
              component: () => import("@/views/gbrk/finance/Hutang.vue"),
            },
            {
              path: 'piutang',
              name: 'piutangGBRK',
              component: () => import("@/views/gbrk/finance/Piutang.vue"),
            },
          ]
        }

      ]
    },

  //Kencana Retail Indonesia
  {
    path: "/admin/kri",
    component: Master,
    redirect: "/admin/kri/dashboard",
    beforeEnter: checkAuth,
    name: "KRI",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewKRIs',
        component: () => import("@/views/kri/Overview.vue")
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OverviewKRI',
            name: 'DashboardKRI',
            component: () => import("@/views/kri/dashboard/OverviewKRI"),
          },
          {
            path: 'gis',
            name: 'gisKRI',
            component: () => import("@/views/kri/dashboard/GISAnalysis"),
          },
        ]
      },
      {
        path: "sales",
        component: Children,
        redirect: "/sales/sales-analysis",
        beforeEnter: checkAuth,
        children: [
          {
            path: 'SalesAnalysis',
            name: 'SalesAnalysisKRI',
            component: () => import("@/views/kri/sales/SalesAnalysis.vue")
          },
          {
            path: 'cash-deposit-report',
            name: 'mnKRICashDeposit',
            component: () => import("@/views/kri/sales/CashDeposit.vue")
          },
          {
            path: 'SalesActivity',
            name: 'SalesActivityKRI',
            component: () => import("@/views/kri/sales/CustomerVisit.vue")
          },
          {
            path: 'VisitReport',
            name: 'VisitReportKRI',
            component: () => import("@/views/kri/sales/VisitReport.vue"),
          },
          {
            path: 'StoreVisit',
            name: 'StoreVisitKRI',
            component: () => import("@/views/kri/sales/StoreVisit.vue"),
          },
        ]
      },
      {
        path: "purchasing",
        component: Children,
        beforeEnter: checkAuth,
        children: [
          {
            path: 'pr-detail',
            name: 'pr-detail',
            component: () => import("@/views/kri/financeApproval/DetailPR.vue")
          },
        ]
      },
      {
        path: "order",
        component: Children,
        beforeEnter: checkAuth,
        children: [
          {
            path: 'OrderList',
            name: 'OrderListKRI',
            component: () => import("@/views/kri/order/OrderList.vue")
          },
        ]
      }
    ]
  },

  //PPSK
  {
    path: "/admin/ppsk",
    component: Master,
    redirect: "/admin/ppsk/dashboard",
    beforeEnter: checkAuth,
    name: "PPSK",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewPPSK',
        component: () => import("@/views/ppsk/OverviewPpsk.vue"),
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OverviewPPSK',
            name: 'OverviewPPSK',
            component: () => import("@/views/ppsk/OverviewPpsk.vue"),
          },
        ]
      },
      {
        path: "approval",
        component: Children,
        redirect: "/admin/ppsk/approval/dashboard",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'mnuApprovalPR',
            name: 'mnuApprovalPR',
            component: () => import("@/views/ppsk/approval/PurchaseRequest.vue"),
          },
          {
            path: 'mnuApprovalPO',
            name: 'mnuApprovalPO',
            component: () => import("@/views/ppsk/approval/PurchaseOrder.vue"),
          },
        ]
      }
    ]
  },

  //MMM
  {
    path: "/admin/mmm",
    component: Master,
    redirect: "/admin/mmm/dashboard",
    beforeEnter: checkAuth,
    name: "MMM",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewMMM',
        component: () => import("@/views/mmm/Overview.vue"),
      },
      {
        path: "dashboard",
        component: Children,
        children: [
          {
            path: 'AnalyticDashboard',
            name: 'AnalyticDashboardMMM',
            component: () => import("@/views/mmm/AnalyticDashboard.vue"),
          },
        ]
      },
      {
        path: "report",
        component: Children,
        redirect: "/admin/mmm/report",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OrderReport',
            name: 'OrderReportMMM',
            component: () => import("@/views/mmm/OrderReport.vue"),
          }
        ]
      }
    ]
  },

  //Handy
  {
    path: "/admin/hst",
    component: Master,
    redirect: "/admin/hst/dashboard",
    beforeEnter: checkAuth,
    name: "HST",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewHandy',
        component: () => import("@/views/hst/Overview.vue"),
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuth,
        children: [
          {
            path: 'gis',
            name: 'gisHST',
            component: () => import("@/views/hst/dashboard/GISAnalysis"),
          },
        ]
      },
      {
        path: "sales",
        component: Children,
        redirect: "/admin/hst/sales",
        beforeEnter: checkAuth,
        children: [
          {
            path: 'SalesActivity',
            name: 'SalesActivityHST',
            component: () => import("@/views/hst/sales/CustomerVisit.vue")
          },
          {
            path: 'VisitReport',
            name: 'VisitReportHST',
            component: () => import("@/views/hst/sales/VisitReport.vue"),
          },
        ]
      }
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let programmatic = false
;(['push', 'replace', 'go', 'back', 'forward']).forEach(methodName => {
  const method = router[methodName]
  router[methodName] = (...args) => {
    programmatic = true
    method.apply(router, args)
  }
})

router.beforeEach((to, from, next) => {
  // name is null for initial load or page reload
  if (from.name === null || programmatic) {
    // triggered bu router.push/go/... call
    // route as usual
    next()
  } else {
    // triggered by user back/forward 
    // do not route
    next(false)
  }
  programmatic = false // clear flag
})

export default router;