<template>
    <v-container fluid style="padding-bottom:100px" class="pa-5">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="col-6 pa-0 pr-1" md="2">
                <span class="mb-0 font-12">Office</span>
                <v-autocomplete
                    v-model="office_id"
                    :items="offices"
                    item-value="office_id"
                    item-text="office"
                    outlined
                    dense
                    class="ma-0 pa-0 mt-1"
                    hide-details=true
                ></v-autocomplete>
            </v-col>
            <v-col class="col-6 pa-0 pr-1" md="2">
                <span class="mb-0 font-12">Vendor</span>
                <v-autocomplete
                    v-model="vendor"
                    :items="vendors"
                    item-value="vendor_id"
                    item-text="vendor_name"
                    outlined
                    dense
                    class="ma-0 pa-0 mt-1"
                    hide-details=true
                ></v-autocomplete>
            </v-col>
            <v-col class="col-6 pa-0 pr-1" md="2">
                <span class="mb-0 font-12">Status</span>
                <v-autocomplete
                    v-model="status"
                    :items="statuses"
                    item-value="value"
                    item-text="text"
                    outlined
                    dense
                    class="ma-0 pa-0 mt-1"
                    hide-details=true
                ></v-autocomplete>
            </v-col>
            <v-col class="col-6 pa-0 pr-1" md="2">
                <span class="mb-0 font-12">Start Date <span class="red--text"><strong>* </strong></span></span>
                <v-menu
                    ref="modal"
                    v-model="modal"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        outlined
                        dense
                        v-model="date_from"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0 pa-0 mt-1"
                        hide-details=true
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_from"
                        no-title
                        @input="modal = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col class="col-6 pa-0 pr-1" md="2">
                <span class="mb-0 font-12">End Date <span class="red--text"><strong>* </strong></span></span>
                <v-menu
                    ref="modal_to"
                    v-model="modal_to"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                        outlined
                        dense
                        v-model="date_to"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0 pa-0 mt-1"
                        hide-details=true
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="date_to"
                        no-title
                        @input="modal_to = false"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col class="col-6 pa-0 pr-1" md="2" lg="1">
                <v-btn @click="searchData()" depressed color="primary" size="small" class="pb-0" style="margin-top: 18px;">GO</v-btn>
            </v-col>
            <v-col class="col-12" md="12">
                <v-divider class="mt-2 mb-2 pt-0 pb-0"></v-divider>
            </v-col>
            <v-col class="col-12" md="12">
                <v-alert
                    text
                    color="success"
                    class="ml-2 mb-0 rounded-l pa-1"
                    v-show="display_alert"
                    dense
                >
                    <v-row
                        align="center"
                        no-gutters
                    >
                        <span class="mr-5">
                            {{selectReqs.length}} Selected
                        </span>
                        <v-col v-if="approvedDisabled === true" class="col-6" sm="6" md ="2" lg ="1">
                            <v-btn 
                                color="success"
                                outlined
                                depressed
                                small
                                block
                                class="mr-2"
                                @click="approve()"
                            >
                                Approve
                            </v-btn>
                        </v-col>
                        <v-col v-if="approved2Disabled === true && totalApprove === 2" class="col-6" sm="6" md ="2" lg ="1">
                            <v-btn 
                                color="success"
                                outlined
                                depressed
                                small
                                block
                                class="mr-2"
                                @click="approveTwo()"
                            >
                                Approve 2
                            </v-btn>
                        </v-col>
                        <v-col class="col-6" sm="6" md ="2" lg ="1">
                            <v-btn v-if="approvedDisabled === true || approved2Disabled === true"
                                color="error"
                                outlined
                                depressed
                                small
                                block
                                class="mr-2"
                                @click="reject()"
                            >
                                Reject
                            </v-btn>
                        </v-col>
                        <v-col class="col-6" sm="6" md ="2" lg ="1">
                            <v-btn v-if="approvedDisabled === true || approved2Disabled === true"
                                color="orange"
                                outlined
                                small
                                block
                                class="mr-2"
                                @click="reset()"
                            >
                                Reset Approve
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    fixed-header
                    height="490"
                    :headers="headers"
                    :items="items"
                    :loading="loading"
                    loading-text="Please wait, retrieving data"
                    :items-per-page="30"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                    }"
                    class="elevation-1 mt-2"
                    :search="search"
                    @toggle-select-all="selectAll"
                    @item-selected="selectItem"
                    item-key="purc_dp_req_id"
                    show-select
                    v-model="selected"
                >
                    <template v-slot:top>
                        <v-toolbar flat color="white" class="mt-2 mb-2">
                            <div class="d-flex w-100">
                                <!-- <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> </h6> -->
                                <v-spacer></v-spacer>
                                <v-text-field outlined style="max-width: 350px;" class="border-12 mr-3 pa-0 ma-0" v-model="search" append-icon="mdi-magnify" dense single-line hide-details></v-text-field>
                            </div>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.dt_req`]="{ item }">
                        {{ item.dt_req ? (new Date(new Date(item.dt_req) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ') : '' }}
                    </template>
                    <template v-slot:[`item.purc_dp_req_id`]="{ item }">
                        {{ item.purc_dp_req_id.trim() }}
                    </template>
                    <template v-slot:[`item.rate`]="{ item }">
                        {{ $store.getters.convertToCurrency(item.rate) }}
                    </template>
                    <template v-slot:[`item.total_amt`]="{ item }">
                        {{ $store.getters.convertToCurrency(item.total_amt) }}
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <v-chip
                            v-if="item.aprv_flag == 'Y'"
                            class="ma-1"
                            color="success"
                            label
                            outlined
                            small
                        >
                            Approved
                        </v-chip>
                        <v-chip
                            v-if="item.aprv_flag == 'N' && item.aprv1_by.trim() != '' && item.aprv2_by.trim() === ''"
                            class="ma-1"
                            color="success"
                            label
                            outlined
                            small
                        >
                            Approved 1
                        </v-chip>
                        <v-chip
                            v-if="item.aprv_flag == 'N' && item.rjc_date == '1900-01-01 00:00:00.000' && item.aprv1_by.trim() === '' && item.aprv2_by.trim() === ''"
                            class="ma-1"
                            color="orange"
                            label
                            outlined
                            small
                        >
                            Not Approved
                        </v-chip>
                        <v-chip
                            v-if="item.aprv_flag == 'N' && item.rjc_date != '1900-01-01 00:00:00.000'"
                            class="ma-1"
                            color="error"
                            label
                            outlined
                            small
                        >
                            Rejected
                        </v-chip>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon small class="mr-2" @click="showDetail(item)" v-on="on">
                                    mdi-eye
                                </v-icon>
                            </template>
                            <span>Show</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <v-dialog
                v-model="dialog"
                persistent
                min-width="1000"
                max-width="1000"
                >
                    <v-card style="overflow: auto;">
                        <v-card-title class="text-center mb-0 pb-0">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex">
                                        <div class="ml-0">
                                        Detil
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="mx-0"
                                            text
                                            @click="dialog = false"
                                        >
                                            <v-icon>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text class="ma-0 pt-0 ">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-card outlined>
                                            <v-card-text>
                                                <v-container fluid>
                                                    <v-row>
                                                        <v-col class="col-12 pa-0" md="3">
                                                            <span class="mb-0 font-12">TR ID</span>
                                                            <p>
                                                            {{ detailItem ? detailItem.purc_dp_req_id : '' }} 
                                                            </p>            
                                                        </v-col>
                                                        <v-col class="col-12 pa-0 pr-1" md="2">
                                                            <span class="mb-0 font-12">Date Req </span> 
                                                            <p>
                                                            {{ dt_req ? (new Date(new Date(dt_req) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).replace('T', ' ') : '' }}
                                                            </p> 
                                                        </v-col>
                                                        <v-col class="col-12 pa-0 pr-1" md="2" >
                                                            <span class="mb-0 font-12">Office </span>
                                                            <p>
                                                            {{ detailItem ? detailItem.office : ''}}
                                                            </p>
                                                        </v-col>
                                                        <v-col class="col-12 pa-0 pr-1" md="3">
                                                            <span class="mb-0 font-12">Vendor </span>
                                                            <p>
                                                            {{ detailItem ? detailItem.vendor : ''}}

                                                            </p>
                                                        </v-col> 
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-data-table
                                                                fixed-header
                                                                height="400"
                                                                :headers="header_items"
                                                                :items="detailItems"
                                                                :loading="loading"
                                                                loading-text="Please wait, retrieving data"
                                                                :items-per-page="30"
                                                                :footer-props="{
                                                                    'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                                                }"
                                                                class="elevation-1 mt-2"
                                                                :search="search_item"
                                                            >
                                                                <template v-slot:top>
                                                                    <v-toolbar flat color="white" class="mt-2 mb-2">
                                                                        <div class="d-flex w-100">
                                                                            <v-spacer></v-spacer>
                                                                            <v-text-field outlined style="max-width: 350px;" class="border-12 mr-3 pa-0 ma-0" v-model="search_item" append-icon="mdi-magnify" dense single-line hide-details></v-text-field>
                                                                        </div>
                                                                    </v-toolbar>
                                                                </template>
                                                                <template v-slot:[`item.req_dp_amt`]="{ item }">
                                                                    {{ $store.getters.convertToCurrency(item.req_dp_amt) }}
                                                                </template>
                                                                <template v-slot:[`item.inv_amt`]="{ item }">
                                                                    {{ $store.getters.convertToCurrency(item.inv_amt) }}
                                                                </template>
                                                            </v-data-table>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                                
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"
import { applConstant } from "../../../backend-api/sms/shared/appl_constant"

export default {
  data() {
    return {
        snackbar: {
            color: null,
            icon: null,
            mode: null,
            position: "top",
            text: null,
            timeout: 7500,
            title: null,
            visible: false
        },
        breadcumbs: [
            {
                text: 'Surya Maju Sukses',
                disabled: false,
                href: '/admin/sms',
            },
            {
                text: 'Approval',
                disabled: true,
                href: 'breadcrumbs_link_2',
            },
            {
                text: 'Purchase Deposit Request',
                disabled: true,
                href: 'breadcrumbs_link_2',
            },
        ],
        approvedDisabled: false,
        approved2Disabled: false,
        offices: [],
        office_id: '',
        vendor: '',
        vendors: [],
        date_from: '',
        modal: false,
        date_to: '',
        modal_to: false,
        statuses: [
            { text: 'Approved', value: 'approve1'},
            { text: 'Not Approved', value: 'notApprove1'},
            { text: 'Rejected', value: 'reject'},
        ],
        status: '',
        headers: [
            { text: 'Date Req', align: 'start', value: 'dt_req', width: '50px' },
            { text: 'TR ID ', align: 'start', sortable: true, value: 'purc_dp_req_id', width: '80px' },
            { text: 'Office ', align: 'start', sortable: true, value: 'office', width: '100px' },
            { text: 'Vendor ', align: 'start', sortable: true, value: 'vendor', width: '150px' },
            { text: 'Curr ID ', align: 'start', sortable: true, value: 'curr_id', width: '60px' },
            { text: 'Rate ', align: 'end', sortable: true, value: 'rate', width: '60px' },
            { text: 'Total ', align: 'end', sortable: true, value: 'total_amt', width: '100px' },
            { text: 'Status ', align: 'center', sortable: true, value: 'status', width: '100px' },
            { text: ' ', align: 'end', sortable: true, value: 'action', width: '10px' },
        ],
        items: [],
        search: '',
        loading: false,
        selectReqs: [],
        display_alert: false,
        dialog: false,
        header_items: [
            { text: 'Seq Num', align: 'start', value: 'seq_num', width: '50px' },
            { text: 'Remark', align: 'start', sortable: true, value: 'remark'},
            { text: 'Req Amt ', align: 'end', sortable: true, value: 'req_dp_amt', width: '100px' },
            { text: 'PO ID', align: 'start', sortable: true, value: 'po_id', width: '100px' },
            { text: 'INV ID', align: 'start', sortable: true, value: 'inv_id', width: '100px' },
            { text: 'INV Amt', align: 'end', sortable: true, value: 'inv_amt', width: '100px' },
            { text: 'User', align: 'start', sortable: true, value: 'user_id', width: '100px' },
            
        ],
        detailItems: [],
        search_item: '',
        detailItem: {},
        dt_req: '',
        selected: [],
        totalApprove:0,
      }
    },
    computed: {
    },
    async mounted(){
        await this.getOffice()
        await this.getVendor()
        await this.getApplConstant()
        await this.getAprv1()
        await this.getAprv2()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        async getApplConstant(){
            let reqBody = {
                "key_code" : ['APRVDPNUM'],
                "str1" : [],
                "str2" : [],
                "str3" : [],
                "str4" : [],
                "int1" : [],
                "int2" : [],
                "int3" : [],
            }
            const respData = await applConstant.fetchApplConstant(``, reqBody, false, false, false)
            if (respData.status === 200) {
                const data = respData.data.data
                this.totalApprove = data[0].int1
            }
        },
        async getAprv1(){
            this.$store.dispatch('setOverlay', true)
            const respData = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=STARCON&appl_id=WEBSTARCON&var_id=APRVDPR`, null, false, false, false)  
            if (respData.status == 200) {
                if (respData.data.data == "Y"){
                    this.approvedDisabled = true
                } else {
                    this.approvedDisabled = false
                }
                // await this.retreiveIndex()
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Failed to get env conf.",
                    visible: true
                };
            }
        },
        async getAprv2(){
            this.$store.dispatch('setOverlay', true)
            const respData = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=STARCON&appl_id=WEBSTARCON&var_id=APRVDPR2`, null, false, false, false)  
            if (respData.status == 200) {
                if (respData.data.data == "Y"){
                    this.approved2Disabled = true
                } else {
                    this.approved2Disabled = false
                }
                // await this.retreiveIndex()
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: "Failed to get env conf.",
                    visible: true
                };
            }
        },
        async getOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/starcon/office`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.offices = res.data
            });
        },
        async getVendor(){
            var reqBody = {
                'brand_id': '',
            }
            const respData = await backendApi.fetchCore(`/api/master/starcon/showVendor`, reqBody, false, false, false) 
            if (respData.status === 200) {
                this.vendors = respData.data
            }
        },
        selectAll(orders){
            if (orders.value === true) {
                this.display_alert = true
                this.selectReqs = orders.items
            } else {
                this.display_alert = false
                this.selectReqs = []
            } 
        },
        selectItem(order){
            if (order.value === true) {
                this.display_alert = true
                this.selectReqs.push(order.item)
            } else {
                for (let index = 0; index < this.selectReqs.length; index++) {
                    if (this.selectReqs[index].purc_dp_req_id.trim() === order.item.purc_dp_req_id.trim()) {
                        this.selectReqs.splice(index, 1);
                        if (this.selectReqs.length <= 0) {
                            this.display_alert = false
                        }
                    }
                }
            } 
        },
        closeAlert(){
            this.display_alert = false
        },
        async searchData(){
            var start_date = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : ''
            var end_date = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : ''

            var reqBody = {
                'office_id': this.office_id ? this.office_id : '',
                'vendor_id': this.vendor ? this.vendor : '',
                'txtApproved': this.status ? this.status : '',
                'start_date' : start_date,
                'end_date' : end_date
            }

            if (start_date === '' || start_date === null || end_date === '' || end_date === null ) {
                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: 'Please fill required!',
                    visible: true
                };
                return false
            }

            this.loading = true
            const respData = await backendApi.fetchCore(`/api/v3/sms/approval/purc_dp_req`, reqBody, false, false, false) 
            if (respData.status === 200) {
                console.log(respData.data);
                this.loading = false
                this.items = respData.data
            }
        },
        async showDetail(item){
            console.log(item);
            this.detailItem = item
            this.dt_req = ''
            this.dt_req = item.dt_req
            var reqBody = {
                'office_id': item.office_id,
                'purc_dp_req_id': item.purc_dp_req_id.trim()
            }
            const respData = await backendApi.fetchCore(`/api/v3/sms/approval/purc_dp_req/show`, reqBody, false, false, false) 
            if (respData.status === 200) {
                this.detailItems = respData.data
                this.dialog = true
            }
        },
        async approve(){
            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                'orders': this.selectReqs,
                'total_approve': this.totalApprove
            }
            const respData = await backendApi.fetchCore(`/api/v3/sms/approval/purc_dp_req/approve`, reqBody, false, false, false) 
            if (respData.status === 200) {
                this.selectReqs = []
                this.selected = []
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Success Approved",
                    visible: true
                };
                this.display_alert = false
                await this.searchData()
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
            
        },
        async approveTwo(){},
        async reject(){
            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                'orders': this.selectReqs
            }
            const respData = await backendApi.fetchCore(`/api/v3/sms/approval/purc_dp_req/reject`, reqBody, false, false, false) 
            if (respData.status === 200) {
                this.selectReqs = []
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Success Rejected",
                    visible: true
                };
                this.selected = []
                this.display_alert = false
                await this.searchData()
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        },
        async reset(){
            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                'orders': this.selectReqs
            }
            const respData = await backendApi.fetchCore(`/api/v3/sms/approval/purc_dp_req/rollback`, reqBody, false, false, false) 
            if (respData.status === 200) {
                this.selectReqs = []
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: "Success rollback",
                    visible: true
                };
                this.selected = []

                this.display_alert = false
                await this.searchData()
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        },
    }
}
</script>