<template>
    <div class="container fluid">
        <div class="row">

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2 col-12">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" label="Region" clearable dense :disabled="disabled_region" @change="(event) => regionOnChange(event)" prepend-icon="mdi-city"></v-select>
                            </div>
                            <div class="col-lg-2 col-12">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" label="Branch" clearable dense :disabled="disabled_office" @change="(event) => officeOnChange(event)" prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-3 col-12">
                                <v-select v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" label="Salesman" clearable dense :disabled="disabled_sales" prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-2 col-12">
                                <v-select v-model="stageModel" :items="stageLists" default="" item-value="value" item-text="text" label="Stage" clearable dense prepend-icon="mdi-check-circle-outline"></v-select>
                            </div>
                            <div class="col-lg-3 col-12">
                                <v-select v-model="typeModel" :items="typeLists" default="" item-value="value" item-text="text" label="Tipe" clearable dense prepend-icon="mdi-check-circle-outline"></v-select>
                            </div>
                            <div class="col-lg-2 col-12">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-12">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-8">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">

                <div id="app" class="app-container">
                    <div class="sidebar">
                        <div class="sidebar-header">
                            <h3>Project List</h3>
                        </div>
                        <div class="search-chat">
                            <input type="text" v-model="searchQuery" placeholder="Search project..." style="width: 100%; height: 40px; padding: 10px; background-color: #F0F2F5; border: none; border-radius: 5px; color: #000;">
                        </div>
                        <div class="chat-list-container">
                            <ul>
                                <li
                                    v-for="chat in filteredChats"
                                    :key="chat.id"
                                    @click="selectChat(chat)"
                                    :class="{ active: chat.id === selectedChat?.id }"
                                >
                                    <div class="chat-item">
                                    <div class="avatar">{{ chat.name.charAt(0) }}</div>
                                    <div class="chat-details">
                                        <div class="chat-name">{{ chat.name }}</div>
                                        <div class="last-message">
                                            {{ chat.type }} ({{ chat.stage }})<br />
                                            <!-- {{ chat.messages[chat.messages.length - 1]?.note || "No messages yet" }} -->
                                            <!-- {{ chat.messages.length != 0 ? chat.messages[chat.messages.length - 1]?.note.slice(0, 35) + "..." : "No messages yet" }} -->
                                            {{ chat.address }}
                                        </div>
                                        <div class="message-footer">
                                            <div class="last-message">
                                                {{ chat.date }}
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                
                    <div class="chat-window">
                        <div v-if="selectedChat">
                            <div class="chat-header">
                                <div class="header-content">
                                    <h4>{{ selectedChat.name }}</h4>
                                    <p class="chat-category">{{ selectedChat.category }}</p>
                                </div>
                                <div class="chat-header-actions">
                                    <!-- <button class="refresh-button" @click="refreshMessage">REFRESH</button> -->
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item @click="refreshMessage">
                                                <v-list-item-title>Refresh</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-if="selectedChat && selectedChat.stageStat !== 'P' && selectedChat.stageStat !== 'U' && selectedChat.stageStat !== 'E'" @click="showCloseProjectBy">
                                                <v-list-item-title>Close project</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>
                            </div>
                            <div class="messages-container" ref="messagesContainer">
                                <div class="messages">
                                    <div class="message" v-for="(msg, index) in selectedChat.messages" :key="index" :class="{ 'message-sender': msg.isSender }">
                                        <span class="message-name">{{ msg.user_id }}</span>
                                        <div class="message-content">
                                            <img v-if="msg.attachment_link" :src="`${baseUrl}${msg.attachment_link}`" alt="Message Image" class="message-image" @click="openModal(`${baseUrl}${msg.attachment_link}`)" />
                                            <span class="message-text">{{ msg.note }}</span>
                                            <div class="message-footer">
                                                <span class="message-time">{{ msg.dt_modified }}</span>
                                                <button class="delete-message" v-if="msg.isSender" @click="deleteMessage(index)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16">
                                                        <path d="M3 6h18v2H3zm3 3h12v12H6zm2 2h8v8H8z" fill="#ff0000"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="message-input">
                                <img v-if="selectedImage" :src="imagePreviewUrl" alt="Image Preview" class="image-preview" />
                                <button v-if="selectedImage" @click="deleteImage" title="Delete Image">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        fill="#fff"
                                        stroke="#fff"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <line x1="6" y1="6" x2="18" y2="18" />
                                        <line x1="6" y1="18" x2="18" y2="6" />
                                    </svg>
                                </button>
                                <input
                                    v-model="newMessage"
                                    @keyup.enter="sendMessage"
                                    type="text"
                                    placeholder="Type a message..."
                                />
                                <input
                                    type="file"
                                    @change="onFileChange"
                                    accept="image/*"
                                    style="display: none;"
                                    ref="fileInput"
                                />
                                <button @click="triggerFileInput" title="Upload Image">
                                    <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="" fill="none">
                                        <title>expressions</title>
                                        <path d="M8.49893 10.2521C9.32736 10.2521 9.99893 9.5805 9.99893 8.75208C9.99893 7.92365 9.32736 7.25208 8.49893 7.25208C7.6705 7.25208 6.99893 7.92365 6.99893 8.75208C6.99893 9.5805 7.6705 10.2521 8.49893 10.2521Z" fill="#fff"></path>
                                        <path d="M17.0011 8.75208C17.0011 9.5805 16.3295 10.2521 15.5011 10.2521C14.6726 10.2521 14.0011 9.5805 14.0011 8.75208C14.0011 7.92365 14.6726 7.25208 15.5011 7.25208C16.3295 7.25208 17.0011 7.92365 17.0011 8.75208Z" fill="#fff"></path>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8221 19.9799C15.5379 21.2537 13.8087 21.9781 12 22H9.27273C5.25611 22 2 18.7439 2 14.7273V9.27273C2 5.25611 5.25611 2 9.27273 2H14.7273C18.7439 2 22 5.25611 22 9.27273V11.8141C22 13.7532 21.2256 15.612 19.8489 16.9776L16.8221 19.9799ZM14.7273 4H 9.27273C6.36068 4 4 6.36068 4 9.27273V14.7273C4 17.6393 6.36068 20 9.27273 20H11.3331C11.722 19.8971 12.0081 19.5417 12.0058 19.1204L11.9935 16.8564C11.9933 16.8201 11.9935 16.784 11.9941 16.7479C11.0454 16.7473 10.159 16.514 9.33502 16.0479C8.51002 15.5812 7.84752 14.9479 7.34752 14.1479C7.24752 13.9479 7.25585 13.7479 7.37252 13.5479C7.48919 13.3479 7.66419 13.2479 7.89752 13.2479L13.5939 13.2479C14.4494 12.481 15.5811 12.016 16.8216 12.0208L19.0806 12.0296C19.5817 12.0315 19.9889 11.6259 19.9889 11.1248V9.07648H19.9964C19.8932 6.25535 17.5736 4 14.7273 4ZM14.0057 19.1095C14.0066 19.2605 13.9959 19.4089 13.9744 19.5537C14.5044 19.3124 14.9926 18.9776 15.4136 18.5599L18.4405 15.5576C18.8989 15.1029 19.2653 14.5726 19.5274 13.996C19.3793 14.0187 19.2275 14.0301 19.0729 14.0295L16.8138 14.0208C15.252 14.0147 13.985 15.2837 13.9935 16.8455L14.0057 19.1095Z" fill="#fff"></path>
                                    </svg>
                                </button>
                                <button @click="sendMessage">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24"
                                        viewBox="0 96 960 960"
                                        width="24"
                                    >
                                        <path
                                            d="M120 936v-240L600 576 120 456V216l720 360-720 360Z"
                                            fill="#fff"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div v-else class="no-chat">
                            <p>Select a project on the list for progress discussion</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="isModalOpen" class="modal" @click="closeModal">
            <span class="close" @click="closeModal">&times;</span>
            <img class="modal-content" :src="currentImage" alt="Zoomed Image" />
        </div>

    </div>
</template>
  
<script>
  export default {
    data() {
      return {
        regionModel: '',
        regionLists: [],
        disabled_region: false,
        officeModel: '',
        officeLists: [],
        disabled_office: false,
        salesModel: '',
        salesLists: [],
        disabled_sales: false,
        stageModel: 'O',
        stageLists: [
            {
                text: 'OPEN',
                value: 'O'
            },
            {
                text: 'CLOSED',
                value: 'C'
            },
        ],
        typeModel: '',
        typeLists: [
            {
                text: 'SWASTA NASIONAL',
                value: 'SWASTA NASIONAL'
            },
            {
                text: 'BUMN',
                value: 'BUMN'
            },
        ],
        modalDateFrom: false,
        dateFrom: '',
        modalDateTo: false,
        dateTo: '',
        chats: [],
        // chats: [
        //   {
        //     id: 1,
        //     name: "PT. HUATHAI CONSTRUCTION INDONESIA",
        //     salesman: "ERWIN KOTO",
        //     messages: [
        //       { text: "PENAWARAN UNTUK PT. HUATHAI CONTRUCTION INDONESIA DI PEMALANG, ATAP TYPE KR-5 TEBAL 0,35 0,40 AZ.150 DAN ECOTRIM TEBAL 0,35 0,40 AZ.150. SAYA TAWARKAN DARI PL PRY - 8%. HARGA SEDANG DIAJUKAN KE OWNER", isSender: false, time: "01:00 pm 12-01-2024" },
        //       { text: "ini gmn lanjutnya?", isSender: true, time: "02:00 pm 12-01-2024" },
        //     ],
        //   },
        //   {
        //     id: 2,
        //     name: "CV. BEKA INDONESIA CABANG PEMALANG",
        //     salesman: "ERWIN KOTO",
        //     messages: [],
        //   },
        // ],
        selectedChat: null,
        newMessage: '',
        newMessageId: '',
        searchQuery: '',
        selectedImage: null,
        baseUrl: process.env.VUE_APP_URL,
        isModalOpen: false,
        currentImage: '',
      };
    },
    async mounted(){
        
        this.initialize()
        
    },
    methods: {

        async initialize(){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/ProjectDiscussion`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesLists = res.data.sales
                    this.disabled_salesman = false
                }

                // this.stageLists = res.data.stage

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                console.log(err);
                
            })

        },

        async getData(){

            this.chats = [];
            this.selectedChat = null

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectDiscussion/getData`, { 

                region: this.regionModel ? this.regionModel : '',
                office: this.officeModel ? this.officeModel : '',
                sales: this.salesmanModel ? this.salesmanModel : '',
                projType: this.typeModel ? this.typeModel : '',
                projStage: this.stageModel ? this.stageModel : '',

            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            }
        )
        .then(res => {
            
            this.$store.dispatch('setOverlay', false)
            this.chats = res.data.result
            // console.log(res.data)
            
        })
        .catch(err => {

            this.$store.dispatch('setOverlay', false)
            console.log(err);
            

        })

        },

        async selectChat(chat) {
            this.selectedChat = []
            this.selectedChat = chat
            this.newMessageId = this.selectedChat.id

            this.$nextTick(() => {
                const container = this.$refs.messagesContainer
                container.scrollTop = container.scrollHeight
            })

        },

        async sendMessage() {
            if (this.newMessage.trim() && this.selectedChat) {
                
                const formData = new FormData();
                formData.append('pr_id', this.newMessageId ? this.newMessageId : '');
                formData.append('note', this.newMessage.trim());
                
                if (this.selectedImage) {
                    formData.append('image', this.selectedImage);
                }

                this.selectedChat.messages.push({ note: this.newMessage.trim(), isSender: true, image: this.selectedImage });

                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectDiscussion/storeFeedback`, formData, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser }`
                    }
                })
                .then(res => {

                    // const updatedChat = res.data.result[0];

                    // const chatIndex = this.chats.findIndex(chat => chat.id === updatedChat.id);
                    // if (chatIndex !== -1) {
                    //     this.chats[chatIndex].messages = updatedChat.messages;
                    // }
                    this.refreshMessage()

                })
                .catch(err => {
                    this.$store.dispatch('setOverlay', false);
                    console.log(err);
                });

                this.$nextTick(() => {
                    const container = this.$refs.messagesContainer;
                    container.scrollTop = container.scrollHeight;
                });
            } else {
                Swal.fire({
                    text: "Please enter a message",
                    icon: "warning"
                });
            }

            this.newMessage = "";
            this.selectedImage = null;
        },

        async refreshMessage(){

            if (this.selectedChat) {
                // this.$store.dispatch('setOverlay', true);

                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectDiscussion/getMessages`, {
                    id: this.selectedChat.id
                }, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser }`
                    }
                })
                .then(res => {
                    const updatedChat = res.data.result[0]

                    const chatIndex = this.chats.findIndex(chat => chat.id === updatedChat.id)
                    if (chatIndex !== -1) {
                        this.chats[chatIndex].messages = updatedChat.messages
                    }

                    this.$nextTick(() => {
                        const container = this.$refs.messagesContainer;
                        container.scrollTop = container.scrollHeight;
                    });

                    // this.$store.dispatch('setOverlay', false)
                })
                .catch(err => {
                    this.$store.dispatch('setOverlay', false)
                    console.log(err)
                })

            }

        },

        async deleteMessage(index) {
            
            if (index < 0 || index >= this.selectedChat.messages.length) {
                console.error("Invalid message index");
                return;
            }

            const message = this.selectedChat.messages[index];
            const messageId = message.id;

            const result = await Swal.fire({
                title: 'Are you sure?',
                text: `You are about to delete the message: "${message.note}"`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'Cancel'
            });

            if (result.isConfirmed) {

                // console.log(message);
                
                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectDiscussion/deleteMessage`, {
                    pr_id: this.newMessageId,
                    seq_num: message.seq_num
                }, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser }`
                    }
                })
                .then(res => {
                    this.selectedChat.messages.splice(index, 1);
                    Swal.fire(
                        'Deleted!',
                        'Your message has been deleted.',
                        'success'
                    );
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire(
                        'Error!',
                        'There was an error deleting your message.',
                        'error'
                    );
                });
            }
        },

        triggerFileInput() {
            this.$refs.fileInput.click()
        },

        onFileChange(event) {
            const file = event.target.files[0]
            if (file) {
                this.selectedImage = file
            }
        },

        deleteImage() {
            this.selectedImage = null;
        },

        openModal(imageUrl) {
            this.currentImage = imageUrl;
            this.isModalOpen = true;
        },

        closeModal() {
            this.isModalOpen = false;
            this.currentImage = '';
        },

        async regionOnChange(id){

            this.$store.dispatch('setOverlay', true);

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/regionOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.officeLists = res.data.office
                this.labelBranch = 'Branch Office(' + res.data.countOffice + ')'
                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

                this.$store.dispatch('setOverlay', false);

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async officeOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/officeOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${this.regionModel ? this.regionModel : ''}&office=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        showCloseProjectBy(){
            Swal.fire({
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Close Project with PO",
                denyButtonText: "Close Project without PO",
                }).then((result) => {
                if (result.isConfirmed) {
                    this.closeProject('Y')
                } else if (result.isDenied) {
                    this.closeProject('N')
                }
            });
        },

        async closeProject(a){
            console.log(this.selectedChat);     
            
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: `You are about to close the project: "${this.selectedChat.name}"`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Close Project!',
                cancelButtonText: 'Cancel'
            });

            if (result.isConfirmed) {
                
                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/ProjectDiscussion/closeProject`, {
                    pr_id: this.selectedChat.id,
                    withPO: a
                }, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser }`
                    }
                })
                .then(res => {
                    Swal.fire(
                        'Closed!',
                        'The Project has been closed successfully.',
                        'success'
                    );
                    this.getData()
                })
                .catch(err => {
                    console.log(err);
                    Swal.fire(
                        'Error!',
                        'There was an error deleting your message.',
                        'error'
                    );
                });
            }
            
        }

    },
    computed: {
        filteredChats() {
            return this.chats.filter((chat) => {
                return chat.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                    chat.messages.some((message) => message.text.toLowerCase().includes(this.searchQuery.toLowerCase()));
            });
        },
        imagePreviewUrl() {
            try {
                return this.selectedImage ? URL.createObjectURL(this.selectedImage) : '';
            } catch (error) {
                console.error('Error creating object URL:', error);
                return '';
            }
        }
    },
  };
</script>
  
<style scoped>
    .app-container {
        display: flex;
        max-height: 70vh;
        background: #f6f7fb;
        font-family: "Arial", sans-serif;
        border: 1px solid #ccc;
        border-radius: 10px;
        overflow: hidden;
    }
  
    .sidebar {
        width: 25%;
        background: #fff;
        border-right: 1px solid #ccc;
        display: flex;
        flex-direction: column;
    }
    
    .sidebar-header {
        padding: 5px;
        background: #fff;
        color: #000;
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
    }

    .chat-list-container {
        flex: 1;
        overflow-y: auto;
        min-height: 200px;
    }
    
    .sidebar ul {
        list-style: none;
        padding: 0;
        margin: 0;
        flex: 1;
        overflow-y: auto;
        min-height: 50vh;
    }
    
    .sidebar li {
        padding: 10px;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;
        transition: background-color 0.2s ease;
    }
    
    .sidebar li:hover {
        background: #a3a3a3;
    }
    
    .sidebar li.active {
        background: #cfcfcf;
    }
    
    .chat-item {
        display: flex;
        align-items: center;
    }
    
    .avatar {
        width: 40px;
        height: 40px;
        background: #3455eb;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 10px;
        font-size: 1.2em;
    }
    
    .chat-details {
        flex: 1;
    }
    
    .chat-name {
        font-weight: bold;
        color: #000;
    }
    
    .last-message {
        font-size: 0.9em;
        color: #3B4A54;
    }
    
    .chat-window {
        flex: 1;
        display: flex;
        flex-direction: column;
        background: #F4F1EB;
        position: relative;
    }

    .messages-container {
        flex: 1;
        overflow-y: auto;
        padding: 10px;
        max-height: 54vh;
    }
    
    .chat-header {
        padding: 5px;
        background: #F0F2F5;
        color: #000;
        font-size: 1.3em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
    }

    .chat-header h3 {
        font-weight: bold;
        font-size: 1.1em;
        margin: 0;
    }

    .header-content {
        display: flex;
        flex-direction: column;
    }

    .messages {
        display: flex;
        flex-direction: column;
        max-height: 100%;
    }
    
    .message {
        background: #fff;
        padding: 10px 15px;
        margin: 5px 0;
        border-radius: 10px;
        max-width: 60%;
        position: relative;
    }

    .message-sender {
        background: #d1f3d1;
        align-self: flex-end;
        text-align: right;
    }

    .message-content {
        display: flex;
        flex-direction: column;
    }

    .message-time {
        font-size: 0.8em;
        color: #808080;
        margin-top: 5px;
        align-self: flex-end;
    }

    .message-input {
        display: flex;
        align-items: center;
        padding: 10px;
        background: #F0F2F5;
        border-top: 1px solid #ccc;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    
    .message-input input {
        flex: 1;
        padding: 10px;
        border: 1px solid #ccc;
        background: #fff;
        border-radius: 20px;
        outline: none;
        color: #000;
    }
    
    .message-input button {
        margin-left: 10px;
        background: #00bfa5;
        border: none;
        border-radius: 50%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    
    .message-input button svg {
        fill: #fff;
    }
    
    .message-input button:hover {
        background: #00796b;
    }
    
    .no-chat {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #808080;
        font-size: 1.2em;
    }

    .message-name {
        font-weight: bold;
        font-size: 0.9em;
        margin-bottom: 5px;
        display: block;
    }

    .search-chat {
        padding: 15px;
        border-bottom: 1px solid #ccc;
    }

    #search-chat-input {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    #chat-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    #chat-list li {
        padding: 10px;
        border-bottom: 1px solid #ccc;
    }

    #chat-list li:hover {
        background-color: #f0f0f0;
    }

    .chat-header-actions {
        display: flex;
        align-items: center;
    }

    .refresh-button {
        padding: 5px 8px; /* Adjust padding for a denser button */
        background-color: #007bff; /* Button background color */
        color: white; /* Button text color */
        border: none; /* Remove border */
        border-radius: 5px; /* Rounded corners */
        cursor: pointer; /* Pointer cursor on hover */
        font-size: 0.9em; /* Slightly smaller font size for the button */
    }

    .refresh-button:hover {
        background: #0056b3;
    }

    .refresh-button svg {
        fill: #fff;
        width: 24px;
        height: 24px;
    }

    .message-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 5px;
    }

    .message-time {
        font-size: 0.8em;
        color: #808080;
        margin-right: 5px;
    }

    .delete-message {
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
    }

    .image-preview {
        max-width: 100%;
        max-height: 200px;
        border: 1px solid #ccc;
        border-radius: 8px;
        margin-top: 10px;
        object-fit: cover;
    }

    .message-image {
        width: 100px;
        cursor: pointer;
    }

    .modal {
        display: flex;
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(0, 0, 0, 0.8);
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        max-width: none;
        max-height: none;
        width: auto;
        height: auto;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 30px;
        color: white;
        font-size: 40px;
        font-weight: bold;
        cursor: pointer;
    }

    .chat-category {
        font-size: 0.8em;
        color: #808080; 
        margin: 2px 0 0;
    }

</style>
  